import React, {useEffect, useState} from "react";
import "./Editors.css"
import {useTheme} from "../../utils/ThemeProvider";
import {AuthorData, PublicationData} from "../../constants/Types";
import { EditorsColor} from "../../constants/Colors";
import useMobile from "../../utils/useMobile";
import {MinWidth} from "../../constants/Dims";
import ImgDisplay from "../../common/ImgDisplay";
import {Link} from "react-router-dom";
import {countPublicationsById, filterEditor, filterNotDraft} from "../../common/FilterUtils";
import {getAuthors, getPublications} from "../../request/Request";
import {Loader} from "../../common/Loader";

const Editors: React.FC = () => {
    const [editorsStaff, setEditorsStaff] = useState<AuthorData[]>([])
    const [editorsVisited, setEditorsVisited] = useState<AuthorData[]>([])
    const [loader, setLoader] = useState(true)
    const [pubData, setPubData] = useState<PublicationData[]>([])
    const {isMob} = useMobile(MinWidth)
    const { setBackground } = useTheme();

    useEffect(() => {
        setBackground(EditorsColor);
    }, [setBackground]);

    useEffect(() => {
        Promise.all([
            getAuthors(null),
            getPublications(null),
        ])
            .then(([authorsData, pubData]) => {
                if (pubData){
                    setPubData(pubData)
                }
                if (authorsData){
                    const notDraft = filterNotDraft(authorsData)
                    const editors = filterEditor(notDraft)
                    const visited =  editors.filter((item) => item.editor?.is_visited)
                    const staff =  editors.filter((item) => !item.editor?.is_visited)
                    setEditorsVisited(visited)
                    setEditorsStaff(staff)
                }
                setLoader(false)
            })
            .catch((e) => {
                console.error(e)
            });
    }, [setEditorsStaff, setEditorsVisited, setPubData]);
    if (loader) {
        return <Loader/>
    }
    return (
        <div className={"editors"}>
            <EditorsSection isMob={isMob} editors={editorsStaff} isVisited={false} pubData={pubData} title={"постоянные редакторы"}/>
            <EditorsSection isMob={isMob} editors={editorsVisited} isVisited={true} pubData={pubData} title={"приглашенные редакторы"}/>
        </div>
    )
}
export default Editors

type EditorsSectionProps = {
    editors: AuthorData[],
    isVisited: boolean,
    isMob: boolean,
    pubData: PublicationData[],
    title: string,
}

const EditorsSection: React.FC<EditorsSectionProps> = ({editors, isVisited, isMob, pubData, title}) => {
    return (
        <section className="editors-section">
            <h2 style={{fontWeight: "400", fontSize: "+180%"}}>{title}</h2>
            <div className="editors-grid">
                {editors.map((editor, index) => (
                    <div key={editor._id} className="editor-card"
                         style={CalculateStyleCard(index, editors.length, isVisited, isMob)}>
                        <Link to={`/editor/${editor._id}`}>
                            <ImgDisplay style={{width: "150px", height: "150px"}} src={editor.photo_link}/>
                        </Link>
                        <div className="editor-info">
                            <div className="editor-name">
                                <Link to={`/editor/${editor._id}`}>{editor.name} {editor.surname}</Link>
                            </div>
                            <div className="editor-email">
                                <a href={`mailto:${editor.editor?.email}?subject=${encodeURIComponent('проект волны~')}`}>{editor.editor?.email}</a>
                            </div>
                            <div className="editor-publications">
                                {countPublicationsById({_id: editor._id, pub: pubData, asEditor: true})} ред. публикаций
                            </div>
                        </div>
                    </div>
                ))}
                {
                    editors.length % 2 !== 0 && (
                        <div key="placeholder" className="editor-card" style={{height: "150px"}}>
                        </div>
                    )
                }
            </div>
        </section>
    )
}


const CalculateStyleCard = (index: number, len: number, isVisited: boolean, isMob: boolean) => {
    let style: React.CSSProperties = {}
    if (!isMob && index >= len - 3 && isVisited) {
        style.borderBottom = "none"
    }
    if (!isMob && index % 2 === 0) {
        style.borderRight = "1px solid #0e0b0b"
        style.borderBottom = "1px solid #0e0b0b"
    }
    if (!isMob && index % 2 !== 0) {
        style.paddingLeft = '20px'
    }
    return style
}

