import { URLs } from "../constants/Urls";
import {PublicationData, AuthorData, StaticPageData, Credits, Token} from "../constants/Types";
import {addEntity, deleteEntity, editEntity, getEntity, getEntityWithParams, ID} from "../utils/ReqestEntityUtils";

// Get Publications
export function getPublications(id: ID | null): Promise<PublicationData[]> {
    return getEntity<PublicationData[]>(URLs.Publication.Get, id);
}

// Edit Publications
export function editPublications(data: PublicationData): Promise<any> {
    console.log(data)
    return editEntity<PublicationData>(URLs.Publication.Admin, data);
}

export function addPublications(data: PublicationData): Promise<any> {
    console.log(data)
    return addEntity<PublicationData>(URLs.Publication.Admin, data);
}
export function deletePublications(id: string): Promise<string>{
    return deleteEntity(URLs.Publication.Admin, id);
}


// Get Authors
export function getAuthors(id: ID | null): Promise<AuthorData[]> {
    return getEntity<AuthorData[]>(URLs.Author.Get, id);
}

// Edit Authors
export function editAuthors(data: AuthorData): Promise<any> {
    return editEntity<AuthorData>(URLs.Author.Admin, data);
}

export function addAuthors(data: AuthorData): Promise<any> {
    return addEntity<AuthorData>(URLs.Author.Admin, data);
}

// DELETE Author
export function deleteAuthors(id: string): Promise<string> {
    return deleteEntity(URLs.Author.Admin, id);
}


// Get Static Pages
export function getStaticPages(id: ID | null): Promise<StaticPageData[]> {
    return getEntity<StaticPageData[]>(URLs.StaticPage.Get, id);
}

// Edit Static Pages
export function editStaticPages(data: StaticPageData): Promise<any> {
    return editEntity<StaticPageData>(URLs.StaticPage.Admin, data);
}

export function loginRequest(data: Credits): Promise<Token> {
    return getEntityWithParams<Token, Credits>(URLs.Login, data);
}
