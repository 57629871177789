// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.align-arrow {
    position: relative;
    bottom: -0.3em;
    width: auto; /* Scale width to maintain aspect ratio */
    height: 1.2em; /* Match the height to the font size of the link */
}

.align-wave{
    position: relative;
}

.align-search{
    position: relative;
    width: auto; /* Scale width to maintain aspect ratio */
    margin-left: 0.5em; /* Add some space between the text and the icon */
}

@media (max-width: 700px){
    .align-wave{
        bottom: -1.6pc;
        width: auto;
        height: 2em;
    }
    .align-search {
        height: 2.5em;
        bottom: -0.7em;
    }

}
@media (min-width: 700px){
    .align-search{
        height: 1.2em; /* Match the height to the font size of the link */
        bottom: -0.3em;
    }
    .align-wave{
        bottom: -1.5pc;
        width: 2.8rem;
        height: 1.8em;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/menu/Icons.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,cAAc;IACd,WAAW,EAAE,yCAAyC;IACtD,aAAa,EAAE,kDAAkD;AACrE;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,WAAW,EAAE,yCAAyC;IACtD,kBAAkB,EAAE,iDAAiD;AACzE;;AAEA;IACI;QACI,cAAc;QACd,WAAW;QACX,WAAW;IACf;IACA;QACI,aAAa;QACb,cAAc;IAClB;;AAEJ;AACA;IACI;QACI,aAAa,EAAE,kDAAkD;QACjE,cAAc;IAClB;IACA;QACI,cAAc;QACd,aAAa;QACb,aAAa;IACjB;AACJ","sourcesContent":[".align-arrow {\n    position: relative;\n    bottom: -0.3em;\n    width: auto; /* Scale width to maintain aspect ratio */\n    height: 1.2em; /* Match the height to the font size of the link */\n}\n\n.align-wave{\n    position: relative;\n}\n\n.align-search{\n    position: relative;\n    width: auto; /* Scale width to maintain aspect ratio */\n    margin-left: 0.5em; /* Add some space between the text and the icon */\n}\n\n@media (max-width: 700px){\n    .align-wave{\n        bottom: -1.6pc;\n        width: auto;\n        height: 2em;\n    }\n    .align-search {\n        height: 2.5em;\n        bottom: -0.7em;\n    }\n\n}\n@media (min-width: 700px){\n    .align-search{\n        height: 1.2em; /* Match the height to the font size of the link */\n        bottom: -0.3em;\n    }\n    .align-wave{\n        bottom: -1.5pc;\n        width: 2.8rem;\n        height: 1.8em;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
