import React, {useEffect, useState} from "react";
import {PublicationData} from "../../../constants/Types";
import { deletePublications, getPublications} from "../../../request/Request";
import {GetPrettyTimePub} from "../../homepage/HomepageUtils";
import {MenuAdmin} from "../common/MenuAdmin";
import {Link} from "react-router-dom";
import {checkRolesExist} from "../common/Utils";
import {PublicationDelete, PublicationManipulation} from "../../../constants/Roles";
import DeleteButton from "../../../common/DeleteConfirm";

const AllPublication: React.FC = () => {
    const [pubs, setPubs] = useState<PublicationData[] | null>()
    const canDelete = checkRolesExist([PublicationDelete])

    useEffect(() => {
        getPublications(null).then((data) =>{
            const ok = checkRolesExist([PublicationManipulation])
            if (!ok) {
                const ID = localStorage.getItem("ID");
                if (!ID){
                    console.error("ID нет в локалсотрадже")
                    return
                }
                data.filter(pub => pub.editors_ids.includes(ID))
            }
                setPubs(data)
            })

    }, [setPubs]);
    return (
        <>
            <MenuAdmin/>
            <Link to={`/crm/publication/add`}>Добавить</Link>
            {pubs?.map((pub) => (
                <>
                <Link to={`/crm/publication/edit/${pub._id}`}>
                    <h2>{pub.title}</h2>
                    <h2>{GetPrettyTimePub({date: new Date(pub.date_to_publish)})}</h2>
                </Link>
                    {canDelete && <DeleteButton entityName="Публикацию" id={pub._id} onDelete={deletePublications} />}
                </>
    )
            )}
        </>
    )
}

export default AllPublication
