// SearchResults.tsx
import React from 'react';
import {useSearch} from "../../context/SearchContext";

const SearchResults: React.FC = () => {
    const { results, setQuery, setResults } = useSearch();

    const clearSearch = () => {
        setQuery('');
        setResults([]);
    };

    return (
        <div>
            <button onClick={clearSearch}>Clear Search</button>
            <ul>
                {results.map((result: { id: React.Key | null | undefined; title: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; }) => (
                    <li key={result.id}>{result.title}</li>
                ))}
            </ul>
        </div>
    );
};

export default SearchResults;
