// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Styles.css */
.search-container {
    position: relative;
    max-width: 200px;
}

.search-input {
    background-color: var(--background-color);
    width: 0;
    border: none;
    border-radius: 25px;
    transition: width 0.4s ease-in-out;
    cursor: pointer;
    outline: none;
}

.search-input::placeholder {
    color: transparent;
}

.search-input:focus,
.search-input:not(:placeholder-shown) {
    width: 100%;
    cursor: text;
    padding-left: 20px;
    color: #333;
}

.search-input:focus::placeholder,
.search-input:not(:placeholder-shown)::placeholder {
    color: #aaa;
}

.search-icon {
    position: absolute;
    top: 0;
    right: 0;
    background: none;
    border: none;
    outline: none;
    /*padding: 10px;*/
    cursor: pointer;
}

@media (max-width: 700px) {
    .search-container {
        display: inline-block;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/menu/SearchBar.css"],"names":[],"mappings":"AAAA,eAAe;AACf;IACI,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,yCAAyC;IACzC,QAAQ;IACR,YAAY;IACZ,mBAAmB;IACnB,kCAAkC;IAClC,eAAe;IACf,aAAa;AACjB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;;IAEI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,WAAW;AACf;;AAEA;;IAEI,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,MAAM;IACN,QAAQ;IACR,gBAAgB;IAChB,YAAY;IACZ,aAAa;IACb,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI;QACI,qBAAqB;IACzB;AACJ","sourcesContent":["/* Styles.css */\n.search-container {\n    position: relative;\n    max-width: 200px;\n}\n\n.search-input {\n    background-color: var(--background-color);\n    width: 0;\n    border: none;\n    border-radius: 25px;\n    transition: width 0.4s ease-in-out;\n    cursor: pointer;\n    outline: none;\n}\n\n.search-input::placeholder {\n    color: transparent;\n}\n\n.search-input:focus,\n.search-input:not(:placeholder-shown) {\n    width: 100%;\n    cursor: text;\n    padding-left: 20px;\n    color: #333;\n}\n\n.search-input:focus::placeholder,\n.search-input:not(:placeholder-shown)::placeholder {\n    color: #aaa;\n}\n\n.search-icon {\n    position: absolute;\n    top: 0;\n    right: 0;\n    background: none;\n    border: none;\n    outline: none;\n    /*padding: 10px;*/\n    cursor: pointer;\n}\n\n@media (max-width: 700px) {\n    .search-container {\n        display: inline-block;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
