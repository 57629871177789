import React, { useEffect, useState } from "react";
import { useTheme } from "../../utils/ThemeProvider";
import { PublicationColor } from "../../constants/Colors";
import { Link, useParams } from "react-router-dom";
import { AuthorData, PublicationData } from "../../constants/Types";
import { getUrlUser } from "../../common/FilterUtils";
import { Loader } from "../../common/Loader";
import { GetPrettyTimePub } from "../homepage/HomepageUtils";
import ImgDisplay from "../../common/ImgDisplay";
import { getAuthors, getPublications } from "../../request/Request";
import "./Publication.css";

type PublicationProps = {
    draftAvailable: boolean;
};

const Publication: React.FC<PublicationProps> = ({ draftAvailable }) => {
    const { setBackground } = useTheme();
    const [authors, setAuthors] = useState<AuthorData[]>([]);
    const [editors, setEditors] = useState<AuthorData[]>([]);
    const [pubData, setPubData] = useState<PublicationData | null>(null);
    const { id } = useParams();

    useEffect(() => {
        setBackground(PublicationColor);
    }, [setBackground]);

    useEffect(() => {
        if (!id) {
            console.error("no id");
            return;
        }
        Promise.all([getAuthors(null), getPublications(id)])
            .then(([authorsData, publicationsData]) => {
                if (publicationsData.length === 0) {
                    console.error("По такому ID нет публикаций");
                } else {
                    if (!draftAvailable && publicationsData[0].is_draft) {
                        console.error("публикация черновая нельзя показывать здесь");
                    } else {
                        setPubData(publicationsData[0]);
                    }
                }
                const editors = authorsData.filter(user =>
                    publicationsData[0].editors_ids.includes(user._id)
                );
                const authors = authorsData.filter(user =>
                    publicationsData[0].authors_ids.includes(user._id)
                );
                setAuthors(authors);
                setEditors(editors);
            })
            .catch(e => {
                console.error(e);
            });
    }, [setAuthors, setEditors, setPubData, id, draftAvailable]);

    if (!pubData) {
        return <Loader />;
    }
    return (
        <>
            <div className={"publication"}>
                <div className="publication-grid">
                    <div className="publication-grid-item">
                        <div className={"description"}>
                            <div>
                                <h1>{pubData.title}</h1>
                                {authors &&
                                    authors.map(author => (
                                        <Link to={getUrlUser(author)} key={author._id}>
                                            <h1>{author.name} {author.surname}</h1>
                                        </Link>
                                    ))}
                                {editors &&
                                    editors.map(editor => (
                                        <Link to={getUrlUser(editor)} key={editor._id}>
                                            <p>редактор {editor.name} {editor.surname}</p>
                                        </Link>
                                    ))}
                                <p>{GetPrettyTimePub({date: new Date(pubData.date_to_publish)})}</p>
                            </div>
                            <div>
                                {pubData.photo && <p className="photo_description">{pubData.photo.photo_description}</p>}
                            </div>
                        </div>
                    </div>
                    <div className="publication-grid-item image-container">
                        {pubData.photo && pubData.photo.photo_link && (
                            <div>
                                <ImgDisplay src={pubData.photo.photo_link} style={{width: "400px"}}/>
                            </div>
                        )}
                    </div>
                </div>
                <hr/>
                <div dangerouslySetInnerHTML={{__html: pubData.text_html}}/>
            </div>
        </>
    );
};

export default Publication;
