import React, {useEffect, useState} from "react";
import {MenuAdmin} from "../common/MenuAdmin";
import {EditAddCore} from "../common/EditAddCore";
import {editPublications, getAuthors, getPublications} from "../../../request/Request";
import {AuthorData, PublicationData} from "../../../constants/Types";
import {Loader} from "../../../common/Loader";
import {publicationDataSchema, transform} from "./Constants";
import {useParams} from "react-router-dom";
import CustomErrorModal from "../../../common/CustomErrorModal";
import ImageUploader from "../common/ImgDownloader";
import {checkRolesExist} from "../common/Utils";
import {Roles} from "../../../constants/Roles";

const EditPublication: React.FC = () => {
    const [users, setUsers] = useState<AuthorData[] | null>(null)
    const { id } = useParams();
    const [pub, setPub] = useState<PublicationData | null>()
    const [error, setError] = useState<string |null>(null)

    useEffect(() => {
        if (!id) {
            setError("Некорректный ID");
            return;
        }
        Promise.all([
            getAuthors(null),
            getPublications(id),
        ])
            .then(([authorsData, publicationsData]) => {
                setUsers(authorsData);
                if (publicationsData.length === 0) {
                    setError("По такому ID нет публикаций");
                } else {
                    setPub(publicationsData[0]);
                }
            })
            .catch((e) => {
                console.error(e);
                setError(`Ошибка загрузки данных: ${e.message}`);
            });

    }, [setUsers,setPub, setError, id]);

    if (error) {
        return <CustomErrorModal msg={error} />;
    }

    if (!users || !pub) {
        return <Loader/>
    }
    if (pub){
        const ID = localStorage.getItem("ID");
        if (ID && !pub.editors_ids.includes(ID)){
            const ok = checkRolesExist(Roles.Pub.Edit)
            if (!ok) {
                return <CustomErrorModal msg={"у вас сюда нет доступа"} />
            }
        }
    }

    if (!pub.photo){
        pub.photo = {
            photo_description: "",
            photo_link: ""
        }
    }


    return (
        <>
            <MenuAdmin/>
            <ImageUploader/>
            <EditAddCore data={
                pub
            } validationSchema={
                publicationDataSchema
            } onSubmit={
                editPublications
            }
             transform={transform}
             users={
                 users
             }
            />
        </>
    )
}

export default EditPublication