import React, { useState } from "react";
import ConfirmModal from "./ConfirmModal"; // Reusable modal component

interface DeleteButtonProps {
    onDelete: (id: string) => Promise<string>;  // Update to pass `id` to onDelete function
    entityName: string;                       // The name of the entity to delete (e.g., "User", "Post")
    id: string;                               // The unique ID of the entity to delete
}

const DeleteButton: React.FC<DeleteButtonProps> = ({ onDelete, entityName, id }) => {
    const [isModalOpen, setModalOpen] = useState(false);

    const handleDeleteClick = () => {
        setModalOpen(true);  // Show the modal on delete button click
    };

    const handleConfirm = async () => {
        setModalOpen(false); // Close the modal
        await onDelete(id);  // Pass the ID to the delete function
    };

    const handleCancel = () => {
        setModalOpen(false);  // Close the modal if cancel is clicked
    };

    return (
        <>
            <button onClick={handleDeleteClick}>Delete {entityName}</button>
            <ConfirmModal
                title={`Delete ${entityName}`}
                message={`Are you sure you want to delete this ${entityName}? This action cannot be undone.`}
                onConfirm={handleConfirm}   // Call handleConfirm when "Confirm" is clicked
                onCancel={handleCancel}     // Call handleCancel when "Cancel" is clicked
                isOpen={isModalOpen}        // Show the modal based on the state
            />
        </>
    );
};

export default DeleteButton;
