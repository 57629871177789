// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.editor-author-core {
    /*mobile*/
    @media (max-width: 700px){
        .author-grid {
            display: block;
            grid-template-columns: repeat(1, 1fr);
            gap: 10px;
            h3{
                font-size: +90%;
            }
        }
    }

    /*desc*/
    @media (min-width: 700px){
        .author-grid {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 20px;
        }
    }

    .editor_sign{
        background-color: #b0b0b0; /* Gray background */
        color: #000;
        padding: 10px 20px;
        border: none;
        border-radius: 20px; /* Rounded corners */
        font-size: 16px; /* Adjust font size as needed */
        font-weight: bold; /* Make text bold */
        display: inline-block; /* Keep the element inline but allow for padding */
        text-align: center; /* Center the text */
        line-height: 1; /* Adjust line-height for better vertical alignment */
        text-decoration: none; /* Remove underline from text */
    }
}`, "",{"version":3,"sources":["webpack://./src/components/users/EditorAuthorCore.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT;QACI;YACI,cAAc;YACd,qCAAqC;YACrC,SAAS;YACT;gBACI,eAAe;YACnB;QACJ;IACJ;;IAEA,OAAO;IACP;QACI;YACI,aAAa;YACb,qCAAqC;YACrC,SAAS;QACb;IACJ;;IAEA;QACI,yBAAyB,EAAE,oBAAoB;QAC/C,WAAW;QACX,kBAAkB;QAClB,YAAY;QACZ,mBAAmB,EAAE,oBAAoB;QACzC,eAAe,EAAE,+BAA+B;QAChD,iBAAiB,EAAE,mBAAmB;QACtC,qBAAqB,EAAE,kDAAkD;QACzE,kBAAkB,EAAE,oBAAoB;QACxC,cAAc,EAAE,qDAAqD;QACrE,qBAAqB,EAAE,+BAA+B;IAC1D;AACJ","sourcesContent":[".editor-author-core {\n    /*mobile*/\n    @media (max-width: 700px){\n        .author-grid {\n            display: block;\n            grid-template-columns: repeat(1, 1fr);\n            gap: 10px;\n            h3{\n                font-size: +90%;\n            }\n        }\n    }\n\n    /*desc*/\n    @media (min-width: 700px){\n        .author-grid {\n            display: grid;\n            grid-template-columns: repeat(2, 1fr);\n            gap: 20px;\n        }\n    }\n\n    .editor_sign{\n        background-color: #b0b0b0; /* Gray background */\n        color: #000;\n        padding: 10px 20px;\n        border: none;\n        border-radius: 20px; /* Rounded corners */\n        font-size: 16px; /* Adjust font size as needed */\n        font-weight: bold; /* Make text bold */\n        display: inline-block; /* Keep the element inline but allow for padding */\n        text-align: center; /* Center the text */\n        line-height: 1; /* Adjust line-height for better vertical alignment */\n        text-decoration: none; /* Remove underline from text */\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
