import { ReactComponent as ArrowBlack } from "../../static/icons/arrow_black.svg"
import { ReactComponent as ArrowGrey } from "../../static/icons/arrow_grey.svg"
import {useTheme} from "../../utils/ThemeProvider";
import { ReactComponent as SearchBlack } from "../../static/icons/search_black.svg"
import { ReactComponent as SearchGrey } from "../../static/icons/search_grey.svg"
import { ReactComponent as WaveBlack } from "../../static/icons/wave_blackv2.svg"
import { ReactComponent as WaveGrey } from "../../static/icons/wave_grey.svg"
import "./Icons.css"
import React from "react";

export const ArrowIcon = ({style}: any) => {
    const {background} = useTheme()

    const darkGrey = '#252525'

    if (background === darkGrey) {
        return <ArrowGrey style={style} className={"align-arrow"} />
    } else {
        return <ArrowBlack style={style} className={"align-arrow"} />
    }
}

export const SearchIcon = () => {
    const {background}= useTheme()

    const darkGrey = '#252525'
    if (background === darkGrey) {
        return <SearchGrey className={"align-search"} />
    } else {
        return <SearchBlack className={"align-search"} />
    }
}

export const WaveIcon = () => {
    const {background} = useTheme()

    const darkGrey = '#252525'
    if (background === darkGrey) {
        return <WaveGrey className={"align-wave"} />
    } else {
        return <WaveBlack className={"align-wave"} />
    }
}