import React, { useState } from 'react';
import { Field, Formik, Form, FieldArray } from 'formik';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { AuthorData } from "../../../constants/Types";
import { convertUTCDateToLocalDate } from "../../homepage/HomepageUtils";

type FieldConfig = {
    name: string;
    type: string;
    label: string;
    isRichText?: boolean;
};

type EditAddCoreProps = {
    data: any;
    validationSchema: any;
    onSubmit: (values: any) => Promise<void>;
    transform?: (values: any)=> any;
    users?: AuthorData[];
};

export const EditAddCore: React.FC<EditAddCoreProps> = ({ data, validationSchema, onSubmit, users, transform }) => {
    const fields = generateFieldConfig(data);
    const [submitting, setSubmitting] = useState(false);
    const handleSubmit = async (values: any) => {
        try {
            setSubmitting(true);

            if (transform){
                values = transform(values)
            }
            await onSubmit(values);
        } catch (error) {
            console.error("Submission error:", error);
            setSubmitting(false)
        }
    };

    return (
        <>
        <Formik
            validationSchema={validationSchema}
            initialValues={data}
            onSubmit={handleSubmit}
        >
            {({ values, setFieldValue, errors, touched }) => (
                <Form>
                    <h1>Редактирование</h1>

                    {fields.map(({ name, type, label, isRichText }, index) => (
                        <div key={index}>
                            <label htmlFor={name}>{label}:</label>

                            {isRichText ? (
                                <ReactQuill
                                    theme="snow"
                                    // @ts-ignore
                                    value={values[name] || ""}
                                    onChange={(newValue) => setFieldValue(name, newValue)}
                                />
                            ) : type === 'select-array' ? (
                                users && <UserSelector fieldName={name} users={users} />
                            ) : type === 'hide' ? (
                                <></>
                            ) : (
                                <>
                                    { name.toLowerCase().includes("password") &&
                                        <div style={{color:"red"}}>
                                            введи старый пароль или новый
                                        </div>
                                    }
                                    <Field name={name} type={type} />
                                </>
                            )}
                        </div>
                    ))}

                    <button type="submit" disabled={submitting}>
                        {submitting ? "успешно" : "сохранить"}
                    </button>
                </Form>
            )}
        </Formik>
    </>
    );
};

type UserSelectorProps = {
    fieldName: string;
    users: AuthorData[];
};

const UserSelector: React.FC<UserSelectorProps> = ({ fieldName, users }) => {
    return (
        <FieldArray
            name={fieldName}
            render={arrayHelpers => (
                <div>
                    {arrayHelpers.form.values[fieldName]?.map((userId: string, index: number) => (
                        <div key={index}>
                            <Field as="select" name={`${fieldName}.${index}`}>
                                {users.map(user => (
                                    <option key={user._id} value={user._id}>
                                        {user.name} {user.surname}
                                    </option>
                                ))}
                            </Field>
                            <button type="button" onClick={() => arrayHelpers.remove(index)}>Remove</button>
                        </div>
                    ))}
                    <button type="button" onClick={() => arrayHelpers.push('')}>Add User</button>
                </div>
            )}
        />
    );
};

function generateFieldConfig(data: Record<string, any>, parentKey = ''): FieldConfig[] {
    return Object.entries(data).flatMap(([key, value]) => {
        const fieldKey = parentKey ? `${parentKey}.${key}` : key;
        let type: string;
        let isRichText = false;

        if (key.includes('html')) {
            type = 'quill';
            isRichText = true;
        } else if (key.includes('date')) {
            type = 'datetime-local';
            data[key] = convertUTCDateToLocalDate(value);
        } else if (key.includes('_ids')) {
            type = 'select-array';
        } else if (key === '_id') {
            type = 'hide';
        } else if (Array.isArray(value)) {
            type = 'text';
        } else {
            switch (typeof value) {
                case 'string':
                    type = 'text';
                    break;
                case 'boolean':
                    type = 'checkbox';
                    break;
                case 'object':
                    return value !== null ? generateFieldConfig(value, fieldKey) : [{ name: fieldKey, type: 'text', label: fieldKey }];
                default:
                    type = 'text';
            }
        }

        return [{ name: fieldKey, type, label: formatLabel(fieldKey), isRichText }];
    });
}

const formatLabel = (key: string) => key.replace(/\./g, ' ').replace('_', ' ').toUpperCase();
