import React, { useEffect, useState } from "react";
import "./Editors.css";
import { useTheme } from "../../utils/ThemeProvider";
import { AuthorData, PublicationData } from "../../constants/Types";
import { EditorsColor } from "../../constants/Colors";
import useMobile from "../../utils/useMobile";
import { MinWidth } from "../../constants/Dims";
import ImgDisplay from "../../common/ImgDisplay";
import { Link } from "react-router-dom";
import { countPublicationsById, filterEditor, filterNotDraft } from "../../common/FilterUtils";
import { getAuthors, getPublications } from "../../request/Request";
import { Loader } from "../../common/Loader";

const Editors: React.FC = () => {
    const [editorsStaff, setEditorsStaff] = useState<AuthorData[]>([]);
    const [editorsVisited, setEditorsVisited] = useState<AuthorData[]>([]);
    const [loader, setLoader] = useState(true);
    const [pubData, setPubData] = useState<PublicationData[]>([]);
    const { isMob } = useMobile(MinWidth);
    const { setBackground } = useTheme();

    useEffect(() => {
        setBackground(EditorsColor);
    }, [setBackground]);

    useEffect(() => {
        Promise.all([getAuthors(null), getPublications(null)])
            .then(([authorsData, pubData]) => {
                if (pubData) setPubData(pubData);
                if (authorsData) {
                    const notDraft = filterNotDraft(authorsData);
                    const editors = filterEditor(notDraft);
                    setEditorsVisited(editors.filter((item) => item.editor?.is_visited));
                    setEditorsStaff(editors.filter((item) => !item.editor?.is_visited));
                }
                setLoader(false);
            })
            .catch(console.error);
    }, []);

    if (loader) return <Loader />;

    return (
        <div className="editors">
            <EditorsSection isMob={isMob} editors={editorsStaff} title="Постоянные редакторы" pubData={pubData} />
            <EditorsSection isMob={isMob} editors={editorsVisited} title="Приглашенные редакторы" pubData={pubData} />
        </div>
    );
};

export default Editors;

type EditorsSectionProps = {
    editors: AuthorData[];
    isMob: boolean;
    pubData: PublicationData[];
    title: string;
};

const EditorsSection: React.FC<EditorsSectionProps> = ({ editors, isMob, pubData, title }) => {
    const columns = isMob ? 1 : 2;
    const rows = Math.ceil(editors.length / columns);

    return (
        <section className="editors-section">
            <h2 className="editors-title">{title}</h2>
            <div className="editors-grid" style={{ gridTemplateColumns: `repeat(${columns}, 1fr)` }}>
                {editors.map((editor, index) => (
                    <div key={editor._id} className="editor-card" style={calculateCardStyle(index, editors.length, columns, rows)}>
                        <Link to={`/editor/${editor._id}`}>
                            <ImgDisplay style={{ width: "150px", height: "150px" }} src={editor.photo_link} />
                        </Link>
                        <div className="editor-info">
                            <div className="editor-name">
                                <Link to={`/editor/${editor._id}`}>{editor.name} {editor.surname}</Link>
                            </div>
                            <div className="editor-email">
                                <a href={`mailto:${editor.editor?.email}?subject=${encodeURIComponent('проект волны~')}`}>{editor.editor?.email}</a>
                            </div>
                            <div className="editor-publications">
                                {countPublicationsById({ _id: editor._id, pub: pubData, asEditor: true })} ред. публикаций
                            </div>
                        </div>
                    </div>
                ))}
                {editors.length % columns !== 0 && <div key="placeholder" className="editor-card" style={{ visibility: "hidden" }}></div>}
            </div>
        </section>
    );
};

const calculateCardStyle = (index: number, length: number, columns: number, rows: number) => {
    const borderStyle = "1px solid #0e0b0b";
    let style: React.CSSProperties = {};

    const isLastColumn = (index + 1) % columns === 0 || index === length - 1;
    const isLastRow = Math.floor(index / columns) + 1 === rows;

    if (!isLastRow) style.borderBottom = borderStyle;
    if (!isLastColumn) style.borderRight = borderStyle;

    return style;
};
