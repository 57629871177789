import React, {useEffect} from "react";
import {useTheme} from "../utils/ThemeProvider";
import "./Loader.css"
import LoaderBlack from "../static/loader/loader_black.gif"
import {HomepageColor} from "../constants/Colors";

const MIN_DISPLAY_TIME: number = 1000;
export const Loader: React.FC = () => {

    useEffect(() => {
        const timer = setTimeout(() => {
        }, MIN_DISPLAY_TIME);

        return () => clearTimeout(timer);
    }, []);
    const {background} = useTheme()
    let colorLoader: string
    switch (background){
        case HomepageColor:
            colorLoader = LoaderBlack
            break
        default:
            colorLoader = LoaderBlack
            break
    }
    return (
        <div className={"loader"}>
            <div className={"loader-wrapper"} >
                <img className={"loader-img"} src={colorLoader} alt="loader" />
            </div>
        </div>
    )
}