import React from "react";

type CalculateBordersHomeProps = {
    index: number,
    len: number,
    windowWidth: number,
    isMob: boolean,
    isEmpty: boolean,
}
export const CalculateBordersHome = (
    { index, len, windowWidth, isMob }: CalculateBordersHomeProps
): React.CSSProperties => {
    const borderStyle = "0.5px solid #000000";
    let style: React.CSSProperties = {};

    if (isMob) {
        style.borderBottom = borderStyle;
        if (index === 0) style.borderTop = borderStyle;
        return style;
    }

    const minColumnWidth = 250;
    const columns = Math.floor(windowWidth / minColumnWidth);
    const totalRows = Math.ceil(len / columns); // Total rows in the grid
    const currentRow = Math.floor(index / columns) + 1; // Current row number
    const isFirstRow = currentRow === 1;
    const isLastRow = currentRow === totalRows;
    const isLastColumn = (index + 1) % columns === 0;

    if (index % columns !== 0) style.borderLeft = borderStyle; // Left border for all except first column
    if (!isFirstRow) style.borderTop = borderStyle; // Top border for all except first row
    if (!isLastRow) style.borderBottom = borderStyle; // Remove bottom border if in last row
    if (!isLastColumn) style.borderRight = borderStyle; // Remove right border for last in row

    return style;
};



type CalculateEmptyGridsProps = {
    len: number,
    windowWidth: number,
    isMob: boolean
}

export function convertUTCDateToLocalDate(dateString: string) {
    const date = new Date(dateString);
    const newDate = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
    return newDate.toISOString().slice(0, 16);
}
export const CalculateEmptyGrids: React.FC<CalculateEmptyGridsProps> = ({ len, windowWidth, isMob }) => {
    const minColumnWidth = 250;
    const columns = Math.floor(windowWidth / minColumnWidth);
    const numEmpty = columns - (len % columns);

    if (numEmpty === columns) return null;

    return (
        <>
            {Array.from({ length: numEmpty }, (_, index) => (
                <div
                    key={len + index}
                    style={CalculateBordersHome({
                        index: len + index,
                        len: len + numEmpty,
                        windowWidth: windowWidth,
                        isMob: isMob,
                        isEmpty: true,
                    })}
                    className="grid-item"
                />
            ))}
        </>
    );
};



type GetPrettyTimePubProps = {
    date: Date
}

export const GetPrettyTimePub = ({date}: GetPrettyTimePubProps): string => {
    const now: Date = new Date();
    const timeDifference = (now.getTime() - date.getTime()) / (1000 * 60 * 60 * 24);

    const days = Math.floor(timeDifference);
    switch (days){
        case -1:
        case 0:
            return "сегодня"
        case 1:
            return "1 день назад"
        case 2:
            return "2 дня назад"
        case 3:
            return "3 дня назад"
        case 4:
            return "4 дня назад"
        case 5:
            return "5 дней назад"
        case 6:
            return "6 дней назад"
        case 7:
            return "неделю назад"
        default:
            return `${date.getDate() < 10 ? "0" : ""}${date.getDate()}.${date.getMonth() +1 < 10 ? "0" : ""}${date.getMonth() + 1}.${date.getFullYear()}`
    }
};