import React, { useState } from 'react';

type CdnResponse = {
    uuid: string
}

const MB_1: number = 1048576
const CdnSaveUrl: string = "https://cdn.alialiev.com/images/save"
const CdnGetUrl: string = "https://cdn.alialiev.com/images/"

type ImageUploaderProps = {
    styleDiv?: React.CSSProperties,
    styleImg?: React.CSSProperties,
    cdnGetUrl?: string,
    cdnSaveUrl?: string,
    isSquare?: boolean,
    maxFileSize?: number,
}

const ImageUploader: React.FC<ImageUploaderProps> = ({
                                                         styleDiv = { width: '200px', height: '300px' },
                                                         styleImg = { width: '100px', height: '100px' },
                                                         cdnSaveUrl= CdnSaveUrl,
                                                         cdnGetUrl = CdnGetUrl,
                                                         isSquare = true,
                                                         maxFileSize = MB_1
                                                     }) => {
    const [image, setImage] = useState<null | File>(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [uploadStatus, setUploadStatus] = useState('Загрузите фото не более 1МB');
    const [url, setUrl] = useState('');

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];

            if (file.size > maxFileSize) {
                setErrorMessage(`Error: Image size exceeds: file size: [${file.size}]`);
                return;
            }
            const imageObject = new Image();

            imageObject.onload = () => {
                if (isSquare && imageObject.width !== imageObject.height) {
                    setErrorMessage('Error: Image is not square');
                } else {
                    setImage(file);
                    setErrorMessage('');
                }
            };
            imageObject.onerror = () => {
                setErrorMessage('Error: Invalid image file');
            };
            imageObject.src = URL.createObjectURL(file);
        }
    };

    const handleUpload = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!image) {
            setUploadStatus('Please select a file to upload.');
            return;
        }

        const formData = new FormData();
        formData.append('image', image);

        try {
            const response = await fetch(cdnSaveUrl, {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                const result: CdnResponse = await response.json();
                setUrl(`${cdnGetUrl}${result.uuid}`);
                setUploadStatus('Upload successful. URL to copy:');
            } else {
                setUploadStatus('Upload failed: ' + response.statusText);
            }
        } catch (error) {
            setUploadStatus('Upload error: ' + (error as Error).message);
        }
    };

    const handleCopy = async () => {
        try {
            await navigator.clipboard.writeText(url);
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <div style={styleDiv}>
            <div style={styleImg}>
                <img src={url} style={{width: "100%", height: "100%"}} alt="Uploaded" />
            </div>
            <form onSubmit={handleUpload}>
                <input type="file" accept="image/*" onChange={handleImageChange} />
                <button type="submit">Upload Image</button>
            </form>
            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
            <p>{uploadStatus}</p>
            {url && <button onClick={handleCopy}>Copy Url</button>}
        </div>
    );
};

export default ImageUploader;
