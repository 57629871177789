import React from "react";

interface ConfirmModalProps {
    title: string;
    message: string;
    onConfirm: () => void;
    onCancel: () => void;
    isOpen: boolean;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({
                                                       title,
                                                       message,
                                                       onConfirm,
                                                       onCancel,
                                                       isOpen,
                                                   }) => {
    if (!isOpen) return null;

    return (
        <div className="confirm-modal">
            <div className="modal-content">
                <h3>{title}</h3>
                <p>{message}</p>
                <div className="modal-actions">
                    <button onClick={onConfirm}>Confirm</button>
                    <button onClick={onCancel}>Cancel</button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmModal;
