import React, {useEffect, useState} from "react";
import {AuthorData} from "../../../constants/Types";
import {editAuthors, getAuthors} from "../../../request/Request";
import {MenuAdmin} from "../common/MenuAdmin";
import {useParams} from "react-router-dom";
import CustomErrorModal from "../../../common/CustomErrorModal";
import {Loader} from "../../../common/Loader";
import {EditAddCore} from "../common/EditAddCore";
import {authorValidationSchema, editorValidationSchema} from "./Constants";
import ImageUploader from "../common/ImgDownloader";
import {checkRolesExist} from "../common/Utils";
import {Roles, RolesDisplay} from "../../../constants/Roles";
import {transform} from "./Constants";

type EditUsersCoreProps = {
    isEditor: boolean;
};

const EditUsersCore: React.FC<EditUsersCoreProps> = ({isEditor}) => {
    const [user, setUser] = useState<AuthorData | null>(null);
    const { id } = useParams();
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        if (!id) {
            setError("Некорректный ID");
            return;
        }
        getAuthors(id).then((data) => {
            if (data.length === 0) {
                setError("По такому ID нет публикаций");
            } else {
                if (data[0].editor && !isEditor) {
                    setError("Некорректная логика редактор-автор");
                    return;
                }
                if (!data[0].editor && isEditor) {
                    setError("Некорректная логика редактор-автор");
                    return;
                }
                const userData = { ...data[0] };
                if (!isEditor) {
                    delete userData.editor; // Remove the editor field
                }
                setUser(userData);
            }
        }).catch((e) => {
            console.error(e);
            setError(`Ошибка загрузки автора: ${e}`);
        });
    }, [id, isEditor]);

    if (error) {
        return <CustomErrorModal msg={error} />;
    }
    const ok = checkRolesExist(isEditor ? Roles.Main.Edit : Roles.Author.Edit)
    if (!ok) {
        return <CustomErrorModal msg={"у вас сюда нет доступа"} />
    }

    if (!user) {
        return <Loader />;
    }

    return (
        <>
            <MenuAdmin />
            <ImageUploader/>
            {isEditor && <RolesDisplay/>}
            <EditAddCore
                data={user}
                validationSchema={isEditor ? editorValidationSchema : authorValidationSchema}
                onSubmit={editAuthors}
                transform={isEditor ? transform: undefined}
            />
        </>
    );
};

export default EditUsersCore;
