import React from "react";
import "./Modal.css"

interface ConfirmModalProps {
    title: string;
    message: string;
    onConfirm: () => void;
    onCancel: () => void;
    isOpen: boolean;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({
                                                       title,
                                                       message,
                                                       onConfirm,
                                                       onCancel,
                                                       isOpen,
                                                   }) => {
    if (!isOpen) return null;

    return (
        // <div className="confirm-modal">
        //     <div className="modal-content">
        //         <h3>{title}</h3>
        //         <p>{message}</p>
        //         <div className="modal-actions">
        //             <button onClick={onConfirm}>Confirm</button>
        //             <button onClick={onCancel}>Cancel</button>
        //         </div>
        //     </div>
        // </div>
        <div className={"modal"}>
            <div className="modal-overlay">
                <div className="modal-container">
                    <div className="modal-header">
                        <h2>{title}</h2>
                    </div>
                    <div className="modal-body">
                        <p>{message}</p>
                    </div>
                    <div className="modal-footer">
                        <button className="close-button" onClick={onConfirm}>подтвердить</button>
                        <button className="close-button" onClick={onCancel}>отмена</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConfirmModal;
