// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader{
    .loader-img{
        max-width: 700px;
    }
    .loader-wrapper{
        place-items: center; /* Center both horizontally and vertically */
        display: grid;
        height: 100vh;
    }
}`, "",{"version":3,"sources":["webpack://./src/common/Loader.css"],"names":[],"mappings":"AAAA;IACI;QACI,gBAAgB;IACpB;IACA;QACI,mBAAmB,EAAE,4CAA4C;QACjE,aAAa;QACb,aAAa;IACjB;AACJ","sourcesContent":[".loader{\n    .loader-img{\n        max-width: 700px;\n    }\n    .loader-wrapper{\n        place-items: center; /* Center both horizontally and vertically */\n        display: grid;\n        height: 100vh;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
