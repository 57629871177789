// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal {
    .modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
    }

    .modal-container {
        background: #fff;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        width: 90%;
        max-width: 400px;
        text-align: center;
    }

    .modal-header h2 {
        margin: 0;
    }

    .modal-body p {
        margin: 20px 0;
    }

    .modal-footer {
        display: flex;
        justify-content: center;
    }

    .close-button {
        padding: 10px 20px;
        background: #007bff;
        border: none;
        border-radius: 4px;
        color: #fff;
        cursor: pointer;
    }

    .close-button:hover {
        background: #0056b3;
    }

}`, "",{"version":3,"sources":["webpack://./src/common/CustomErrorModal.css"],"names":[],"mappings":"AAAA;IACI;QACI,eAAe;QACf,MAAM;QACN,OAAO;QACP,WAAW;QACX,YAAY;QACZ,8BAA8B;QAC9B,aAAa;QACb,uBAAuB;QACvB,mBAAmB;QACnB,aAAa;IACjB;;IAEA;QACI,gBAAgB;QAChB,aAAa;QACb,kBAAkB;QAClB,yCAAyC;QACzC,UAAU;QACV,gBAAgB;QAChB,kBAAkB;IACtB;;IAEA;QACI,SAAS;IACb;;IAEA;QACI,cAAc;IAClB;;IAEA;QACI,aAAa;QACb,uBAAuB;IAC3B;;IAEA;QACI,kBAAkB;QAClB,mBAAmB;QACnB,YAAY;QACZ,kBAAkB;QAClB,WAAW;QACX,eAAe;IACnB;;IAEA;QACI,mBAAmB;IACvB;;AAEJ","sourcesContent":[".modal {\n    .modal-overlay {\n        position: fixed;\n        top: 0;\n        left: 0;\n        width: 100%;\n        height: 100%;\n        background: rgba(0, 0, 0, 0.5);\n        display: flex;\n        justify-content: center;\n        align-items: center;\n        z-index: 1000;\n    }\n\n    .modal-container {\n        background: #fff;\n        padding: 20px;\n        border-radius: 8px;\n        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n        width: 90%;\n        max-width: 400px;\n        text-align: center;\n    }\n\n    .modal-header h2 {\n        margin: 0;\n    }\n\n    .modal-body p {\n        margin: 20px 0;\n    }\n\n    .modal-footer {\n        display: flex;\n        justify-content: center;\n    }\n\n    .close-button {\n        padding: 10px 20px;\n        background: #007bff;\n        border: none;\n        border-radius: 4px;\n        color: #fff;\n        cursor: pointer;\n    }\n\n    .close-button:hover {\n        background: #0056b3;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
