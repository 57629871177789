// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu-admin {
    padding-bottom: 5rem;
    .menu-admin-tab{
        display: inline-block;
        margin-right: 2rem ;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/admin/common/MenuAdmin.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB;QACI,qBAAqB;QACrB,mBAAmB;IACvB;AACJ","sourcesContent":[".menu-admin {\n    padding-bottom: 5rem;\n    .menu-admin-tab{\n        display: inline-block;\n        margin-right: 2rem ;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
