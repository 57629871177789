// @ts-ignore
import React, {createContext, useContext, useState, ReactNode, useEffect} from 'react';
import {Black, DarkGrey, LightGrey, White} from "../constants/Colors";

interface ThemeContextType {
    background: string;
    setBackground: (color: string) => void;
}

const ThemeContext = createContext<ThemeContextType>({
    background: '#fff',
    setBackground: () => {}
});

export const useTheme = () => useContext(ThemeContext);

interface ThemeProviderProps {
    children: ReactNode;
}

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
    const [background, setBackground] = useState<string>('#ffffff');
    useEffect(() => {
        document.body.style.backgroundColor = background;
        SetColor(background)
    }, [background]);
    return (
        <ThemeContext.Provider value={{ background, setBackground }}>
            {children}
        </ThemeContext.Provider>
    );
};
const SetColor = (color: string) => {
    switch (color) {
        case White:
            document.body.style.setProperty('--text-color', Black);
            break
        case LightGrey:
            document.body.style.setProperty('--text-color', Black);
            break
        case DarkGrey:
            document.body.style.setProperty('--text-color', White);
            break
        default:
            document.body.style.setProperty('--text-color', Black);

    }
}
