import React, {useEffect, useState} from "react";
import {StaticPageData} from "../../../constants/Types";
import {editStaticPages, getStaticPages} from "../../../request/Request";
import CustomErrorModal from "../../../common/CustomErrorModal";
import {Loader} from "../../../common/Loader";
import {MenuAdmin} from "../common/MenuAdmin";
import {EditAddCore} from "../common/EditAddCore";
import {spValidationSchema} from "./Constants";
import {checkRolesExist} from "../common/Utils";
import {Roles} from "../../../constants/Roles";

const EditStaticPage: React.FC = () => {
    const [sp, setSp] = useState<StaticPageData[] | null>(null)
    const [error, setError] = useState<string |null>(null)

    useEffect(() => {
        getStaticPages(null)
            .then((data) => {
                                    setSp(data)
                                        })
            .catch((e) => {
                console.error(e)
                setError(e)
                }
            )
    }, [setSp]);

    if (error) {
        return <CustomErrorModal msg={error} />;
    }

    if (!sp) {
        return <Loader/>
    }
    if (sp.length !== 2) {
        return <CustomErrorModal msg={"должно быть две старницы"} />;
    }

    const ok = checkRolesExist(Roles.SP.Edit)
    if (!ok) {
        return <CustomErrorModal msg={"у вас сюда нет доступа"} />
    }

    return (
        <>
            <MenuAdmin/>
            <EditAddCore data={
                sp[0]
            } validationSchema={
                spValidationSchema
            } onSubmit={
                editStaticPages
            }
            />
            <hr/>
            <EditAddCore data={
                sp[1]
            } validationSchema={
                spValidationSchema
            } onSubmit={
                editStaticPages
            }
            />
        </>
    )
}

export default EditStaticPage