import * as yup from 'yup';

const photoSchema = yup.object().shape({
    photo_link: yup.string().url().optional(),
    photo_description: yup.string().optional(),
});

export const publicationDataSchema = yup.object().shape({
    _id: yup.string().required('_id is required'),
    title: yup.string().required('Title is required'),
    text_html: yup.string().required('Text HTML is required'),
    date_to_publish: yup.date().required('Date to publish is required'),
    is_draft: yup.boolean().required('Is draft is required'),
    authors_ids: yup.array().of(yup.string().required()).required('Authors IDs are required'),
    editors_ids: yup.array().of(yup.string().required()).required('Editors IDs are required'),
    // photo: photoSchema.optional(),
});

export const publicationDataSchemaAdd = yup.object().shape({
    _id: yup.string().optional(),
    title: yup.string().required('Title is required'),
    text_html: yup.string().required('Text HTML is required'),
    date_to_publish: yup.date().required('Date to publish is required'),
    is_draft: yup.boolean().optional(),
    authors_ids: yup.array().of(yup.string()),
    editors_ids: yup.array().of(yup.string()),
    photo: photoSchema.optional(),
});