import {AuthorData, Editor, PublicationData} from "../constants/Types";

interface Draftable {
    date_to_publish?: Date;
    is_draft: boolean;
}

interface User {
    editor?: Editor
}
export function filterNotDraft<T extends Draftable>(data: T[]): T[] {
    const now: Date = new Date(); // Current date in local timezone

     data.filter(item => {
        if (!item.is_draft) {
            if (!item.date_to_publish) {
                return true; // No publish date, include in result
            } else {
                const publishDate: Date = new Date(item.date_to_publish);


                return publishDate.getTime() > now.getTime();
            }
        } else {
            return false; // Exclude drafts
        }
    });

    return data
}


export function filterNotEditor<T extends User>(data: T[]): T[] {
    return data.filter(item =>
        !item.editor
    );
}

export function filterEditor<T extends User>(data: T[]): T[] {
    return data.filter(item =>
        item.editor
    );
}

export function getUrlUser(user: AuthorData): string {
    return `/${!user.editor ? "author" : "editor"}/${user._id}`
}

interface UtilProps {
    _id: string;
    pub: PublicationData[];
    asEditor: boolean;
}

export const countPublicationsById = ({ _id, pub, asEditor }: UtilProps): number => {
    const key = asEditor ? 'editors_ids' : 'authors_ids';
    return pub.reduce((count, publication) => {
        if (publication[key].includes(_id)) {
            return count + 1;
        }
        return count;
    }, 0);
};

