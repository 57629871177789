import React, {useEffect, useState} from "react";
import {MenuAdmin} from "../common/MenuAdmin";
import {EditAddCore} from "../common/EditAddCore";
import {addPublications, getAuthors} from "../../../request/Request";
import {AuthorData} from "../../../constants/Types";
import {Loader} from "../../../common/Loader";
import ImageUploader from "../common/ImgDownloader";
import {publicationDataSchemaAdd, transform} from "./Constants";
import {checkRolesExist} from "../common/Utils";
import {Roles} from "../../../constants/Roles";
import CustomErrorModal from "../../../common/CustomErrorModal";
import * as yup from "yup";

const AddPublication: React.FC = () => {
    const [users, setUsers] = useState<AuthorData[] | null>(null)
    useEffect(() => {
        getAuthors(null).then((data) => {
            setUsers(data)
        })
    }, [setUsers]);
    const ok = checkRolesExist(Roles.Pub.Add)
    if (!ok) {
        return <CustomErrorModal msg={"у вас сюда нет доступа"} />
    }
    if (!users) {
        return <Loader/>
    }
    return (
        <>
            <MenuAdmin/>
            <ImageUploader/>
            <EditAddCore data={
                {
                _id: '',
                title: '',
                text_html: '',
                date_to_publish: new Date(),
                is_draft: false,
                is_explicit: false,
                authors_ids: [],
                editors_ids: [],
                photo: { photo_link: '', description: '' },
            }
            } validationSchema={
                // null
                publicationDataSchemaAdd
            } onSubmit={
                addPublications
            }
            transform={transform}
             users={
                 users
             }
            />
        </>
    )
}

export default AddPublication