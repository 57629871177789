import React, {useEffect, useState} from "react";
import {StaticPageData} from "../../constants/Types";
import {Loader} from "../../common/Loader";
import {useTheme} from "../../utils/ThemeProvider";
import {getStaticPages} from "../../request/Request";
import CustomErrorModal from "../../common/CustomErrorModal";


type StaticPageCoreProps = {
    pageID: string,
    colorBackground: string,
}
export const StaticPageCore: React.FC<StaticPageCoreProps> = ({pageID, colorBackground}) => {
    const [page, setPage] = useState<StaticPageData | null>()
    const { setBackground } = useTheme();
    const [error, setError] = useState<string |null>(null)


    useEffect(() => {
        setBackground(colorBackground);
    }, [colorBackground, setBackground]);

    useEffect(() => {
        getStaticPages(pageID).then( (data) =>
            {
                if (data.length === 0) {
                    setError("По такому ID нет публикаций");
                } else {
                    setPage(data[0])
                }
            }
        ).catch( (e) =>
            console.error(e)
        )
    }, [colorBackground, pageID, setBackground]);

    if (error) {
        return <CustomErrorModal msg={error} />;
    }

    if (!page){
        return <Loader/>
    }

    return (
        <>
            <div dangerouslySetInnerHTML={{__html: page.page_html}}/>
        </>
    )
}