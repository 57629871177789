import React, {useEffect, useState} from "react";
import {useTheme} from "../../utils/ThemeProvider";
import {HomepageColor} from "../../constants/Colors";
import {Loader} from "../../common/Loader";
import {AuthorData, MappedPublicationData, PublicationData} from "../../constants/Types";
import {getAuthors, getPublications} from "../../request/Request";
import "./Homepage.css"
import {Link} from "react-router-dom";
import {CalculateBordersHome, CalculateEmptyGrids, GetPrettyTimePub} from "./HomepageUtils";
import {MinWidth} from "../../constants/Dims";
import {filterNotDraft} from "../../common/FilterUtils";
import useMobile from "../../utils/useMobile";

const HomePage: React.FC = () => {
    const [publication, setPublication]
        = useState<PublicationData[] | null>(null)
    const [authors, setAuthors] = useState<AuthorData[]>()
    const [mappedPublication, setMappedPublication]
        = useState<MappedPublicationData[]>([])
    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth - 96 *2);
    const { setBackground } = useTheme();
    const { isMob } =useMobile(MinWidth)

    useEffect(() => {
        setBackground(HomepageColor);
    }, [setBackground]);

    useEffect(() => {
        getPublications(null).then(
            (data: PublicationData[]) => {
                let filtered = filterNotDraft(data)
                setPublication(filtered)
            }
        ).catch( (e) =>
            console.error(e)
        )
    }, [setPublication]);

    useEffect(() => {
        getAuthors(null).then(
            (data: AuthorData[]) => {
                let filtered = data.filter(pub =>
                    !pub.is_draft
                )
                setAuthors(filtered)
            }
        ).catch( (e) =>
            console.error(e)
        )
    }, [setAuthors]);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth - 96 *2);
        };

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, [setWindowWidth]);

    useEffect(() => {
        if (authors && publication) {
            const mappedPub: MappedPublicationData[] = publication.map(pub => {
                const editors = pub.editors_ids
                    .map(id => authors.find(author => author._id === id))
                    .filter((author): author is AuthorData => author !== undefined);

                const authorsArray = pub.authors_ids
                    .map(id => authors.find(author => author._id === id))
                    .filter((author): author is AuthorData => author !== undefined);
                return {
                    _id: pub._id,
                    title: pub.title,
                    editors: editors,
                    authors: authorsArray,
                    date_to_publish: pub.date_to_publish,
                    is_draft: pub.is_draft,
                    text_html: pub.text_html
                };
            });

            setMappedPublication(mappedPub);
        }
    }, [setMappedPublication, authors, publication]);


    if (mappedPublication.length === 0 && publication?.length === 0) {
        return <Loader/>
    }
    return (
        <div className={"homepage"}>
            <p style={{fontSize: "+200%"}}>новые публикации:</p>
            <div className="grid-container">
                {mappedPublication.map((pub, index) => (
                    <Link to={`/publication/${pub._id}`}>

                        <div style={CalculateBordersHome(
                            {index: index, isMob: isMob, windowWidth: windowWidth, len: mappedPublication.length})}
                             className="grid-item">
                            <h5 style={{margin: 0}}>{pub.title}</h5>
                            {pub.authors && pub.authors.map(author => (
                                <h4>{author.name} {author.surname}</h4>
                            ))}
                            <div className={"bottom-grid"}>
                                {!isMob &&
                                    <p> {pub.editors && pub.editors.slice(0, 1).map((editor, index) => (
                                        <div key={index}>ред. {editor.name} {editor.surname}</div>
                                    ))}</p>}
                                <p>{GetPrettyTimePub({date: new Date(pub.date_to_publish)})}</p>
                            </div>
                        </div>
                    </Link>
                ))}
                {/*<CalculateEmptyGrids len={mappedPublication.length} windowWidth={windowWidth} isMob={isMob}/>*/}
            </div>
        </div>
    )
}

export default HomePage