import React from "react";

type CalculateBordersHomeProps = {
    index: number,
    len: number,
    windowWidth: number,
    isMob: boolean,
}

export const CalculateBordersHome = (
    { index, len, windowWidth, isMob }: CalculateBordersHomeProps
): React.CSSProperties => {
    const borderStyle = "0.5px solid #000000";
    let style: React.CSSProperties = {};

    if (isMob) {
        if (index === 0) {
            style.borderBottom = borderStyle;
        } else if (index === len - 1) {
            style.borderTop = borderStyle;
        } else {
            style.borderBottom = borderStyle;
            style.borderTop = borderStyle;
        }
        return style;
    }

    const containerWidth = window.innerWidth * 0.78;

    const minColumnWidth = 250;
    const columns = Math.floor(containerWidth / minColumnWidth);
    const isFirstRow = (index + 1 <= columns);
    const isFirstItem = index % columns === 0;

    if (!isFirstItem && index !== 0 && columns !== 1) {
        style.borderLeft = borderStyle;
    }

    if (!isFirstRow) {
        style.borderTop = borderStyle;
    }
    console.log(len, windowWidth, index,containerWidth, window.innerWidth, columns)


    return style;
}


type CalculateEmptyGridsProps = {
    len: number,
    windowWidth: number,
    isMob: boolean
}

export function convertUTCDateToLocalDate(dateString: string) {
    const date = new Date(dateString);
    const newDate = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
    return newDate.toISOString().slice(0, 16);
}

export  const CalculateEmptyGrids: React.FC<CalculateEmptyGridsProps> = ({len, windowWidth, isMob}) => {
    const containerWidth = windowWidth;
    const minColumnWidth = 250;
    const columns = Math.floor(containerWidth / minColumnWidth);
    const numEmpty = Math.ceil(len / columns) * columns - len
    if (numEmpty === 0) {
        return (
            <></>
        )
    }
    return (
        <>
            {Array.from({length: numEmpty}, (_, index) => (
                <div key={len+ index}
                     style={CalculateBordersHome(
                         {isMob: isMob, len: len, windowWidth: 0, index: index})}
                         className="grid-item"></div>
            ))}
        </>
    )
}

type GetPrettyTimePubProps = {
    date: Date
}

export const GetPrettyTimePub = ({date}: GetPrettyTimePubProps): string => {
    const now: Date = new Date();
    const timeDifference = (now.getTime() - date.getTime()) / (1000 * 60 * 60 * 24);

    const days = Math.floor(timeDifference);
    switch (days){
        case -1:
        case 0:
            return "сегодня"
        case 1:
            return "1 день назад"
        case 2:
            return "2 дня назад"
        case 3:
            return "3 дня назад"
        case 4:
            return "4 дня назад"
        case 5:
            return "5 дней назад"
        case 6:
            return "6 дней назад"
        case 7:
            return "неделю назад"
        default:
            return `${date.getDate() < 10 ? "0" : ""}${date.getDate()}.${date.getMonth() +1 < 10 ? "0" : ""}${date.getMonth() + 1}.${date.getFullYear()}`
    }
};