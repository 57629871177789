import * as yup from 'yup';
import {convertToDesiredFormat} from "../../../utils/DatetimeUtils";

const photoSchema = yup.object().shape({
    photo_link: yup.string().url().optional(),
    photo_description: yup.string().optional(),
});

export const publicationDataSchema = yup.object().shape({
    _id: yup.string().required('_id is required'),
    title: yup.string().required('Title is required'),
    text_html: yup.string().required('Text HTML is required'),
    date_to_publish: yup.date().required('Date to publish is required'),
    is_draft: yup.boolean().required('Is draft is required'),
    is_explicit: yup.boolean().required('Is explicit is required'),
    authors_ids: yup.array().of(yup.string().required()).required('Authors IDs are required'),
    editors_ids: yup.array().of(yup.string().required()).required('Editors IDs are required'),
    // photo: photoSchema.optional(),
});

export const publicationDataSchemaAdd = yup.object().shape({
    _id: yup.string().optional(),
    title: yup.string().required('Title is required'),
    text_html: yup.string().required('Text HTML is required'),
    date_to_publish: yup.date().required('Date to publish is required'),
    is_draft: yup.boolean().optional(),
    is_explicit: yup.boolean().required('Is explicit is required'),
    authors_ids: yup.array().of(yup.string()),
    editors_ids: yup.array().of(yup.string()),
    photo: photoSchema.optional(),
});

export const transform = (values: any): any => {
    const editorID = localStorage.getItem("ID");
    if (!editorID){
        return values
    }
    if (!values.editors_ids){
        values.editors_ids = [editorID]
        return values
    }
    if (!values.editors_ids.includes(editorID)){
        values.editors_ids.push(editorID)
    }
    if (values.photo.photo_link === ""){
        delete values.photo
    }

    if (values.date_to_publish) {
        // @ts-ignore
        values.date_to_publish = convertToDesiredFormat(values.date_to_publish);
    }

    return values
}