import { useState, useEffect } from 'react';
import { isMobileDeviceV3 } from './StyleUtils'; // Adjust the path accordingly

const useMobile = (minWidth: number) => {
    const [isMob, setIsMob] = useState(isMobileDeviceV3(minWidth));

    useEffect(() => {
        const handleResize = () => {
            setIsMob(isMobileDeviceV3(minWidth));
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [minWidth]);

    return { isMob };
};

export default useMobile;
