import React from 'react';
import './App.css';
import { ThemeProvider } from './utils/ThemeProvider';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomePage from './components/homepage/Homapage';
import Menu from './components/menu/Menu';
import { Login } from './components/admin/login/Login';
import AddPublication from './components/admin/publication/Add';
import EditPublication from './components/admin/publication/Edit';
import AllPublication from './components/admin/publication/All';
import EditStaticPage from './components/admin/static_page/Edit';
import AllUsersCore from './components/admin/users/AllUsersCore';
import EditUsersCore from './components/admin/users/EditUsersCore';
import AddUsersCore from './components/admin/users/AddUsersCore';
import { StaticPageCore } from './components/static_page/StaticPageCore';
import { AboutID, SendManuscriptID } from './components/static_page/Constants';
import { AboutColor, SendManuscriptColor } from './constants/Colors';
import EditorAuthorCore from './components/users/EditorAuthorCore';
import Authors from './components/authors/Authors';
import Editors from './components/editors/Editors';
import Publication from './components/publication/Publication';
import { SearchProvider, useSearch } from './context/SearchContext';
import SearchResults from "./components/menu/SearchResult";

const App: React.FC = () => {
    return (
        <ThemeProvider>
            <SearchProvider>
                <BrowserRouter>
                    <Menu />
                    <MainContent />
                </BrowserRouter>
            </SearchProvider>
        </ThemeProvider>
    );
};

const MainContent: React.FC = () => {
    const { query } = useSearch();

    return (
        <>
            {query ? (
                <SearchResults />
            ) : (
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/authors" element={<Authors />} />
                    <Route path="/editors" element={<Editors />} />
                    <Route path="/about" element={<StaticPageCore pageID={AboutID} colorBackground={AboutColor} />} />
                    <Route path="/send_manuscript" element={<StaticPageCore pageID={SendManuscriptID} colorBackground={SendManuscriptColor} />} />
                    <Route path="/publication/:id" element={<Publication draftAvailable={false} />} />
                    <Route path="/publication/draft/:id" element={<Publication draftAvailable={true} />} />
                    <Route path="/author/:id" element={<EditorAuthorCore isEditor={false} canBeDraft={false} />} />
                    <Route path="/author/draft/:id" element={<EditorAuthorCore isEditor={false} canBeDraft={true} />} />
                    <Route path="/editor/:id" element={<EditorAuthorCore isEditor={true} canBeDraft={false} />} />
                    <Route path="/editor/draft/:id" element={<EditorAuthorCore isEditor={true} canBeDraft={true} />} />
                    <Route path="/crm/publication" element={<AllPublication />} />
                    <Route path="/crm/editor" element={<AllUsersCore isEditor={true} />} />
                    <Route path="/crm/author" element={<AllUsersCore isEditor={false} />} />
                    <Route path="/crm/static_page" element={<EditStaticPage />} />
                    <Route path="/crm/login" element={<Login />} />
                    <Route path="/crm/publication/add" element={<AddPublication />} />
                    <Route path="/crm/publication/edit/:id" element={<EditPublication />} />
                    <Route path="/crm/author/add" element={<AddUsersCore isEditor={false} />} />
                    <Route path="/crm/author/edit/:id" element={<EditUsersCore isEditor={false} />} />
                    <Route path="/crm/editor/add" element={<AddUsersCore isEditor={true} />} />
                    <Route path="/crm/editor/edit/:id" element={<EditUsersCore isEditor={true} />} />
                </Routes>
            )}
        </>
    );
};

export default App;
