import React from "react";
import {addAuthors} from "../../../request/Request";
import {MenuAdmin} from "../common/MenuAdmin";
import {EditAddCore} from "../common/EditAddCore";
import {authorInstance, authorValidationSchema, editorInstance, editorValidationSchema} from "./Constants";
import ImageUploader from "../common/ImgDownloader";
import CustomErrorModal from "../../../common/CustomErrorModal";
import {Roles, RolesDisplay} from "../../../constants/Roles";
import {checkRolesExist} from "../common/Utils";
import {transform} from "./Constants";

type AddUsersCoreProps = {
    isEditor: boolean;
};

const AddUsersCore: React.FC<AddUsersCoreProps> = ({isEditor}) => {
    const ok = checkRolesExist(isEditor? Roles.Main.Edit : Roles.Author.Add)
    if (!ok) {
        return <CustomErrorModal msg={"у вас сюда нет доступа"} />
    }
    return (
        <>
            <MenuAdmin />
            <ImageUploader/>
            {isEditor && <RolesDisplay/>}
            <EditAddCore
                data={isEditor? editorInstance: authorInstance}
                validationSchema={isEditor ? editorValidationSchema : authorValidationSchema}
                onSubmit={addAuthors}
                transform={isEditor? transform : undefined}
            />
        </>
    );
};

export default AddUsersCore;
