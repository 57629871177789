import React, {useState, FormEvent} from "react";
import {loginRequest} from "../../../request/Request";
import {useNavigate} from "react-router-dom";

export const Login: React.FC = () => {
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [msg, setMsg] = useState<string>('')
    const navigate = useNavigate()
    if (localStorage.getItem('accessToken')){
        navigate("/crm/publication")
    }
    const handleLogin = async (event: FormEvent) => {
        event.preventDefault();
        try {
            await loginRequest({login: login, password: password}).then(
                (token) => {
                    localStorage.setItem("accessToken", token.access)
                    localStorage.setItem("ID", token._id)
                    localStorage.setItem("Roles", token.roles.join(";"))
                    navigate("/crm/publication")
                }
            )
        } catch (error) {
            console.error('Login failed:', error);
            setMsg('Login failed')
        }
    };
    return (
        <div>
            <h2>Login</h2>
            <form onSubmit={handleLogin}>
                <label>
                    Username:
                    <input
                        type="text"
                        value={login}
                        onChange={(e) => setLogin(e.target.value)}
                    />
                </label>
                <br />
                <label>
                    Password:
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </label>
                <br />
                <button type="submit">Log In</button>
            </form>
            {msg}
        </div>
    );
}

export const handleRelogin = async (event: FormEvent) => {
    localStorage.clear()
    window.location.href = '/crm/login';
};