export type ID = string | null;

interface FetchOptions {
    method: string;
    headers?: Record<string, string>;
    body?: string;
}

async function apiRequest<T>(url: string, options: FetchOptions): Promise<T> {
    const response = await fetch(url, options);
    if (!response.ok) {
        throw new Error(response.statusText);
    }
    return response.json();
}

async function apiRequestWithoutResponseBody(url: string, options: FetchOptions): Promise<string> {
    const response = await fetch(url, options);
    return response.statusText;
}

export function getEntity<T>(url: string, id: ID | null = null): Promise<T> {
    const finalUrl = id ? `${url}?id=${id}` : url;
    return apiRequest<T>(finalUrl, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

interface Id {
    _id?: string
}

export function editEntity<T extends Id>(url: string, data: T): Promise<any> {
    if (data._id === "") {
        data._id = undefined
    }
    return apiRequest<T>(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Token': localStorage.getItem('accessToken') || ""
        },
        body: JSON.stringify(data)
    });
}

export function getEntityWithParams<T, A>(url: string, data: A): Promise<T> {
    return apiRequest<T>(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Token': localStorage.getItem('accessToken') || ""
        },
        body: JSON.stringify(data)
    });
}

export function deleteEntity(url: string, id: string) {
    return apiRequestWithoutResponseBody(`${url}/${id}`, {
        method: 'DELETE',
        headers: {
            'Token': localStorage.getItem('accessToken') || ""
        },
    });
}
