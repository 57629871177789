import * as Yup from "yup";
import {AuthorData} from "../../../constants/Types";

export const authorValidationSchema = Yup.object().shape({
    _id: Yup.string(),
    name: Yup.string().required("Name is required"),
    surname: Yup.string().required("Surname is required"),
    bio: Yup.string().required("Bio is required"),
    photo_link: Yup.string().url("Photo link must be a valid URL").required("Photo link is required"),
    is_draft: Yup.boolean().required("Draft status is required"),
});

export const editorValidationSchema = Yup.object().shape({
    _id: Yup.string(),
    name: Yup.string().required("Name is required"),
    surname: Yup.string().required("Surname is required"),
    bio: Yup.string().required("Bio is required"),
    photo_link: Yup.string().url("Photo link must be a valid URL").required("Photo link is required"),
    is_draft: Yup.boolean(),
    editor: Yup.object().shape({
        is_main: Yup.boolean().required("is_main status is required"),
        is_visited: Yup.boolean().required("is_visited status is required"),
        email: Yup.string().required("is_visited status is required"),
    }).nullable().notRequired()
});

export const authorInstance: AuthorData = {
    _id: "",
    name: "",
    surname: "",
    is_draft: false,
    bio:"",
    photo_link: ""
}

export const editorInstance: AuthorData = {
    _id: "",
    name: "",
    surname: "",
    is_draft: false,
    bio:"",
    photo_link: "",
    editor: {
        is_main: false,
        is_visited: false,
        email: "",
        user: {
            login: "",
            password: "",
            roles: []
        }
    }
}
