const BaseUrl: string = "https://wapi.alialiev.com";
const Admin: string = "/admin";

export const URLs = {
    Publication: {
        Get: BaseUrl + "/publication",
        Admin: BaseUrl + Admin + "/publication"
    },
    Author: {
        Get: BaseUrl + "/author",
        Admin: BaseUrl + Admin + "/author"
    },
    StaticPage: {
        Get: BaseUrl + "/static_page",
        Admin: BaseUrl + Admin + "/static_page"
    },
    Login: BaseUrl + "/login"
};
