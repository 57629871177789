import React, {useEffect, useState} from "react";
import {AuthorData} from "../../../constants/Types";
import {deleteAuthors, getAuthors} from "../../../request/Request";
import {MenuAdmin} from "../common/MenuAdmin";
import {Link} from "react-router-dom";
import ImgDisplay from "../../../common/ImgDisplay";
import DeleteButton from "../../../common/DeleteConfirm";
import {checkRolesExist} from "../common/Utils";
import {AuthorDelete, EditorDelete} from "../../../constants/Roles";

type AllUsersCoreProps = {
    isEditor: boolean;
}
const AllUsersCore: React.FC<AllUsersCoreProps> = ({isEditor}) => {
    const canDelete = checkRolesExist([isEditor? EditorDelete : AuthorDelete])

    const [users, setUsers] = useState<AuthorData[] | null>()
    useEffect(() => {
        getAuthors(null).then((data) =>{
            const filtered = data.filter(user => isEditor ? user.editor : !user.editor)
            setUsers(filtered)
        })

    }, [isEditor, setUsers]);
    return (
        <>
            <MenuAdmin/>
            <Link to={`/crm/${isEditor ? "editor" : "author"}/add`}>Добавить</Link>
            {users?.map((user) => (
                <>
                    <Link to={`/crm/${isEditor ? "editor" : "author"}/edit/${user._id}`}>
                        <h2>{user.name} {user.surname}</h2>
                        <ImgDisplay src={user.photo_link}/>
                    </Link>
                    {canDelete && <DeleteButton entityName="Пользователя" id={user._id} onDelete={deleteAuthors} />}
                </>
                )
            )}
        </>
    )
}

export default AllUsersCore
