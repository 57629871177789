import React, {useEffect, useState} from "react";
import {AuthorData, PublicationData} from "../../constants/Types";
import {useParams} from "react-router-dom";
import {getAuthors, getPublications} from "../../request/Request";
import {Loader} from "../../common/Loader";
import ImgDisplay from "../../common/ImgDisplay";
import ProfilePublications from "../../common/ProfilePublications";
import "./EditorAuthorCore.css"
import {useTheme} from "../../utils/ThemeProvider";
import { UserColor} from "../../constants/Colors";

type EditorAuthorCoreProps = {
    isEditor: boolean,
    canBeDraft: boolean,
}

const EditorAuthorCore: React.FC<EditorAuthorCoreProps> = ({isEditor, canBeDraft}) => {
    const [user, setUser] = useState<AuthorData | null>(null);
    const { id } = useParams();
    const [error, setError] = useState<string | null>(null);
    const [pubAsAuthor, setPubAsAuthor] = useState<PublicationData[] | null>(null)
    const [pubAsEditor, setPubAsEditor] = useState<PublicationData[] | null>(null)
    const {setBackground} = useTheme()

    useEffect(() => {
        setBackground(UserColor);
    }, [setBackground]);

    useEffect(() => {
        if (!id) {
            setError("Некорректный ID");
            return;
        }
        Promise.all([
            getAuthors(id),
            getPublications(null)
        ])
            .then(([authorsData, publicationData]) => {
                if (authorsData.length === 0) {
                    setError("По такому ID нет авторов");
                } else {
                    if (!authorsData[0].editor && isEditor) {
                        setError("Некорректная логика редактор-автор");
                    }
                    setUser(authorsData[0]);
                    if (!publicationData) {
                        setPubAsEditor([])
                        setPubAsAuthor([])
                        return
                    }
                    const asAuthor = publicationData.filter(pub => pub.authors_ids.includes(authorsData[0]._id))
                    setPubAsAuthor(asAuthor)
                    if (isEditor) {
                        const asEditor = publicationData.filter(pub => pub.editors_ids.includes(authorsData[0]._id))
                        setPubAsEditor(asEditor)
                    }
                }
            })
            .catch((e) => {
                setError(`Ошибка загрузки данных: ${e.message}`);
            });

    }, [setUser, setError, id, setPubAsAuthor, setPubAsEditor, isEditor]);

    if (error) {
        console.error(error);
    }

    if (!user) {
        return <Loader/>
    }

    return (
        <div className={"main-body"}>
            <div className={"editor-author-core"}>
                <h1 style={{fontWeight: "400", fontSize: "+230%", marginTop: "1rem"}}>{user.name} {user.surname}</h1>
                <div className={"author-grid"}>
                    <div className={"item-grid"}>
                        <ImgDisplay src={user.photo_link} style={{width:"250px", height: "auto"}}/>
                    </div>
                    <div className={"item-grid"}>
                        {user.editor && (<div className={"editor_sign"}>редактор</div>)}
                        <h3>{user.bio}</h3>
                    </div>
                </div>
                {pubAsAuthor && <ProfilePublications pubAsAuthor={pubAsAuthor} titleText={"личные публикации"}/>}
                {isEditor && pubAsAuthor && <ProfilePublications pubAsAuthor={pubAsEditor || []} titleText={"публикации в качестве редактора"}/>}
            </div>
        </div>
    )
}

export default EditorAuthorCore