import React from 'react';
import './Modal.css';

type CustomErrorModalProps = {
    msg: string;
    onClose?: () => void;
};

const CustomErrorModal: React.FC<CustomErrorModalProps> = ({ msg, onClose = () => window.location.reload() }) => {
    return (
        <div className={"modal"}>
            <div className="modal-overlay">
                <div className="modal-container">
                    <div className="modal-header">
                        <h2>Error</h2>
                    </div>
                    <div className="modal-body">
                        <p>{msg}</p>
                    </div>
                    <div className="modal-footer">
                        <button className="close-button" onClick={onClose}>
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CustomErrorModal;
