export const White = "#FFFFFF"
export const LightGrey = "#DBDBDB"
export const DarkGrey = "#252525"
export const Black = "#090909"

export const HomepageColor: string = LightGrey
export const SendManuscriptColor: string = LightGrey
export const AboutColor: string = LightGrey
export const AuthorsColor: string = White
export const UserColor: string = DarkGrey
export const EditorsColor: string = LightGrey
export const PublicationColor: string = LightGrey