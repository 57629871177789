import React, { useState } from "react";
import { Link } from "react-router-dom";
import { MinWidth } from "../../constants/Dims";
import SearchBar from "./SearchBar";
import {SearchIcon, ArrowIcon, WaveIcon} from "./Icons";
import "./Menu.css";
import useMobile from "../../utils/useMobile";

const Menu: React.FC = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const {isMob} = useMobile(MinWidth)
    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <div className={"menu"}>
            {isMob ? (
                <Mobile
                    isMenuOpen={isMenuOpen}
                    isSearchOpen={isSearchOpen}
                    setIsSearchOpen={setIsSearchOpen}
                    toggleMenu={toggleMenu}
                />
            ) : (
                <Desc />
            )}
        </div>
    );
};

type MobileProps = {
    isMenuOpen: boolean;
    isSearchOpen: boolean;
    setIsSearchOpen: React.Dispatch<React.SetStateAction<boolean>>;
    toggleMenu: React.MouseEventHandler<HTMLDivElement>;
};

const Mobile: React.FC<MobileProps> = ({
                                           isMenuOpen,
                                           isSearchOpen,
                                           setIsSearchOpen,
                                           toggleMenu,
                                       }) => {
    return (
        <div>
            <nav className="navigation">
                <ul className="menuIcons">
                    <li>
                        <h1>
                            <Link to="/">
                                проект волны <WaveIcon />
                            </Link>
                        </h1>
                    </li>
                    {/*<li>*/}
                    {/*    <div*/}
                    {/*        onClick={() => {*/}
                    {/*            setIsSearchOpen(!isSearchOpen);*/}
                    {/*        }}*/}
                    {/*    >*/}
                    {/*        <SearchIcon />*/}
                    {/*    </div>*/}
                    {/*</li>*/}
                    <li>
                        <div
                            className={isMenuOpen ? "burger-icon diam" : "burger-icon"}
                            onClick={toggleMenu}
                        >
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </li>
                </ul>
            </nav>
            {isMenuOpen && (
                <div className="mobLi">
                    <ul>
                        <li>
                            <Link to="/authors">авторы</Link>
                        </li>
                        <li>
                            <Link to="/editors">редакция</Link>
                        </li>
                        <li>
                            <Link to="/about">о проекте</Link>
                        </li>
                        <li>
                            <Link to="/send_manuscript">отправить рукопись</Link>
                        </li>
                        <li>
                            <a
                                href="https://t.me/wavespoetry"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <ArrowIcon /> telegram
                            </a>
                        </li>
                    </ul>
                </div>
            )}
            {isSearchOpen && <SearchBar />}
            <hr />
        </div>
    );
};

const Desc: React.FC = () => {
    return (
        <div className={"desc"}>
            <h1>
                <Link to="/">
                    проект волны <WaveIcon />
                </Link>
            </h1>
            <nav>
                <ul>
                    <li>
                        <Link to="/authors">авторы</Link>
                    </li>
                    <li>
                        <Link to="/editors">редакция</Link>
                    </li>
                </ul>
                <hr />
                <ul>
                    <li>
                        <Link to="/about">о проекте</Link>
                    </li>
                    <li>
                        <Link to="/send_manuscript">отправить рукопись</Link>
                    </li>
                    <li>
                        <a
                            href="https://t.me/wavespoetry"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <ArrowIcon /> telegram
                        </a>
                    </li>
                    {/*<li>*/}
                    {/*    <SearchBar />*/}
                    {/*</li>*/}
                </ul>
                <hr />
            </nav>
        </div>
    );
};

export default Menu;