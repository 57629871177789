import React, {useEffect, useState} from "react";

export const Roles ={
    Author: {
        Edit: "изменять любых авторов",
        Add:  "добавлять авторов",
        Delete: "удалять любых авторов, редакторов",
    },
    Pub: {
        Edit: "изменять любые публикации",
        Add: "делать публикации, изменять свои",
        Delete: "удалять любые публикации",
    },
    SP: {
        Edit: "изменять статические страницы",
    },
    Main:{
        Edit: "изменять редакторов",
        Add:  "добавлять редакторов",
        Delete:"*",
    }
}

export const RolesDisplay: React.FC = () => {
    const [show, setShow] = useState(true)
    useEffect(() => {

    }, []);
    const onClick = (hide: boolean)=>{
        setShow(hide)
    }
    const copyToClipboard = (actions: string) => {
        navigator.clipboard.writeText(actions).then(() => {
        });
    };
    return (
        <div>
            <div onClick={()=>onClick(!show)} style={{display:"flex", cursor:"pointer"}}>
                <span>Роли:</span>
                <span>{show? "⬆️": "⬇️"}</span>
            </div>
            {show &&
                <ul>
                    {Object.entries(Roles).map(([role, actions]) => (
                        <ul key={role}>
                            <strong>{role}:</strong>
                            <ul>
                                {Object.entries(actions).map(([action, description]) => (
                                    <ul key={action}>
                                        <strong>{action}</strong>: {description}
                                        <button
                                            onClick={() => copyToClipboard(description)}
                                            style={{
                                                marginLeft: "10px",
                                                cursor: "pointer",
                                                padding: "5px",
                                                border: "1px solid black",
                                                borderRadius: "5px",
                                                backgroundColor: "#ddd"
                                            }}
                                        >
                                            📋
                                        </button>
                                    </ul>

                                ))}
                            </ul>
                        </ul>
                    ))}
                </ul>
            }
        </div>
    );
};