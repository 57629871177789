// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.editors {
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.editors-title {
    font-weight: 400;
    font-size: 1.8rem;
    margin-bottom: 20px;
}

.editors-grid {
    display: grid;
    gap: 10px;
}

.editor-card {
    display: flex;
    align-items: center;
    padding: 20px;
}

@media (max-width: 700px) {
    .editors-grid {
        grid-template-columns: repeat(1, 1fr);
    }

    .editor-card {
        padding: 5px 0;
    }

    .editor-card img {
        width: 100px;
        margin-right: 5px;
    }
}

@media (min-width: 700px) {
    .editors-grid {
        grid-template-columns: repeat(2, 1fr);
    }

    .editor-card {
        padding: 20px 0;
    }

    .editor-card img {
        width: 200px;
        margin-right: 20px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/editors/Editors.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,SAAS;AACb;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI;QACI,qCAAqC;IACzC;;IAEA;QACI,cAAc;IAClB;;IAEA;QACI,YAAY;QACZ,iBAAiB;IACrB;AACJ;;AAEA;IACI;QACI,qCAAqC;IACzC;;IAEA;QACI,eAAe;IACnB;;IAEA;QACI,YAAY;QACZ,kBAAkB;IACtB;AACJ","sourcesContent":[".editors {\n    display: flex;\n    flex-direction: column;\n    gap: 50px;\n}\n\n.editors-title {\n    font-weight: 400;\n    font-size: 1.8rem;\n    margin-bottom: 20px;\n}\n\n.editors-grid {\n    display: grid;\n    gap: 10px;\n}\n\n.editor-card {\n    display: flex;\n    align-items: center;\n    padding: 20px;\n}\n\n@media (max-width: 700px) {\n    .editors-grid {\n        grid-template-columns: repeat(1, 1fr);\n    }\n\n    .editor-card {\n        padding: 5px 0;\n    }\n\n    .editor-card img {\n        width: 100px;\n        margin-right: 5px;\n    }\n}\n\n@media (min-width: 700px) {\n    .editors-grid {\n        grid-template-columns: repeat(2, 1fr);\n    }\n\n    .editor-card {\n        padding: 20px 0;\n    }\n\n    .editor-card img {\n        width: 200px;\n        margin-right: 20px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
