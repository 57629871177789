import React, {useEffect, useState} from "react";
import {useTheme} from "../../utils/ThemeProvider";
import {getAuthors, getPublications} from "../../request/Request";
import {Loader} from "../../common/Loader";
import {
    countPublicationsById, filterEditor,
    filterNotDraft,
    filterNotEditor,
    getUrlUser
} from "../../common/FilterUtils";
import {AuthorData, GroupedAuthors, PublicationData} from "../../constants/Types";
import {Link} from "react-router-dom";
import ImgDisplay from "../../common/ImgDisplay";
import "./Authors.css"
import {AuthorsColor} from "../../constants/Colors";

const Authors: React.FC = () => {
    const [authors, setAuthors] = useState<GroupedAuthors[] | null>(null)
    const [pubData, setPubData] = useState<PublicationData[]>([])
    const [activeAuthor, setActiveAuthor] = useState<AuthorData | null>(null);
    const [error, setError] = useState<string |null>(null)
    const [uniqueLetters, setUniqueLetters] = useState<string[]>([])
    const { setBackground } = useTheme();
    const handleMouseEnter = (author: AuthorData) => {
        setActiveAuthor(author);
    };

    useEffect(() => {
        setBackground(AuthorsColor);
    }, [setBackground]);

    useEffect(() => {
        Promise.all([
            getAuthors(null),
            getPublications(null),
        ])
            .then(([authorsData, pubData]) => {
                if (pubData){
                    setPubData(pubData)
                }
                if (authorsData){
                    const notDraft: AuthorData[] = filterNotDraft(authorsData)
                    const authors = filterNotEditor(notDraft)
                    const editors = filterEditor(notDraft).filter(
                        (editor) => pubData.some(publication => publication.authors_ids.includes(editor._id))
                    );
                    const allAuthors = [...authors, ...editors]
                    const uniqueLetters: string[] = [...new Set(allAuthors.map(author => author.surname[0].toLowerCase()))];
                    const groupedAuthors: GroupedAuthors[] = uniqueLetters.sort().map(letter => ({
                        letter: letter,
                        authors: allAuthors.filter(author => author.surname[0].toLowerCase() === letter)
                    }));
                    setUniqueLetters(uniqueLetters);
                    setAuthors(groupedAuthors)
                }
            })
            .catch((e) => {
                setError(`Ошибка загрузки данных: ${e.message}`);
            });

    }, [setAuthors, setPubData]);

    if (error) {
        console.error(error)
    }

    if (!authors) {
        return <Loader/>
    }
    return (
        <>
            <div className={"main-body"}>
                <div className={"authors"}>
                    <ul>
                        {uniqueLetters.map(letter => (
                            <li key={letter}>
                                <a style={{fontSize: "+170%", fontWeight: "300"}} href={`#${letter}`}>{letter}</a>
                            </li>
                        ))}
                    </ul>

                    {authors.map(group => (
                        <div className="letter-group" key={group.letter}>
                            <h2 style={{fontSize: "+200%", fontWeight: "400"}} className="divider"
                                id={group.letter}>{group.letter}</h2>
                            {group.authors.map(author => (
                                <Link onMouseOver={() => handleMouseEnter(author)} className={"author"}
                                      to={getUrlUser(author)}>
                                    <div key={author._id}>
                                        <h3 style={{
                                            fontSize: "+200%",
                                            fontWeight: "400"
                                        }}>{author.name} {author.surname}</h3>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    ))}
                    {activeAuthor && (
                        <Link to={getUrlUser(activeAuthor)}>

                            <div className="author-details">
                                <div>
                                    <h2 style={{fontSize: "+200%", fontWeight: "400"}} className="author-title"><Link
                                        style={{color: "white"}}
                                        to={getUrlUser(activeAuthor)}>{activeAuthor.name} {activeAuthor.surname}</Link>
                                    </h2>
                                    <div className={"author-photo"}>
                                        <ImgDisplay src={activeAuthor.photo_link} style={{width: "300px", height: "300px"}}/>
                                    </div>
                                    <p className="author-publications"><Link style={{color: "white"}}
                                                                             to={getUrlUser(activeAuthor)}>{countPublicationsById(
                                                                                 {_id: activeAuthor._id, pub: pubData, asEditor: !!activeAuthor.editor})} публикации</Link>
                                    </p>
                                    {activeAuthor.editor && <p className={"author-editor"}>редактор проекта волны~</p>}
                                </div>

                            </div>
                        </Link>
                    )}
                </div>
            </div>
        </>
    )
}

export default Authors