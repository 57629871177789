// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*mobile*/
@media (max-width: 700px){
    .container-pub{
        padding-left: 0;
    }
    .h3{
        padding-bottom: 5px;
    }
    .title-container {
        display: block; /* Establishes a flex container */
        color: white;
        padding-bottom: 20px;
    }

    .title {
        font-size: 1.5rem;
        padding-bottom: 10px;
    }

    .title-line {
        flex-grow: 1;
        border-bottom: 1px solid white;
    }
}

/*desc*/
@media (min-width: 700px){
    .container-pub {
        padding-left: 30%;
    }
    .h3{
        padding-bottom: 15px;
    }
    .title-container {
        display: flex; /* Establishes a flex container */
        align-items: center; /* Vertically aligns flex items */
        color: white; /* Text color */
        padding-bottom: 20px;
    }

    .title {
        padding-right: 1rem;
        font-size: 1.5rem;
    }

    .title-line {
        flex-grow: 1;
        border-bottom: 1px solid white;
    }

}
`, "",{"version":3,"sources":["webpack://./src/common/ProfilePublications.css"],"names":[],"mappings":"AAAA,SAAS;AACT;IACI;QACI,eAAe;IACnB;IACA;QACI,mBAAmB;IACvB;IACA;QACI,cAAc,EAAE,iCAAiC;QACjD,YAAY;QACZ,oBAAoB;IACxB;;IAEA;QACI,iBAAiB;QACjB,oBAAoB;IACxB;;IAEA;QACI,YAAY;QACZ,8BAA8B;IAClC;AACJ;;AAEA,OAAO;AACP;IACI;QACI,iBAAiB;IACrB;IACA;QACI,oBAAoB;IACxB;IACA;QACI,aAAa,EAAE,iCAAiC;QAChD,mBAAmB,EAAE,iCAAiC;QACtD,YAAY,EAAE,eAAe;QAC7B,oBAAoB;IACxB;;IAEA;QACI,mBAAmB;QACnB,iBAAiB;IACrB;;IAEA;QACI,YAAY;QACZ,8BAA8B;IAClC;;AAEJ","sourcesContent":["/*mobile*/\n@media (max-width: 700px){\n    .container-pub{\n        padding-left: 0;\n    }\n    .h3{\n        padding-bottom: 5px;\n    }\n    .title-container {\n        display: block; /* Establishes a flex container */\n        color: white;\n        padding-bottom: 20px;\n    }\n\n    .title {\n        font-size: 1.5rem;\n        padding-bottom: 10px;\n    }\n\n    .title-line {\n        flex-grow: 1;\n        border-bottom: 1px solid white;\n    }\n}\n\n/*desc*/\n@media (min-width: 700px){\n    .container-pub {\n        padding-left: 30%;\n    }\n    .h3{\n        padding-bottom: 15px;\n    }\n    .title-container {\n        display: flex; /* Establishes a flex container */\n        align-items: center; /* Vertically aligns flex items */\n        color: white; /* Text color */\n        padding-bottom: 20px;\n    }\n\n    .title {\n        padding-right: 1rem;\n        font-size: 1.5rem;\n    }\n\n    .title-line {\n        flex-grow: 1;\n        border-bottom: 1px solid white;\n    }\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
