import React, { useState, useRef } from 'react';
import './SearchBar.css';
import { SearchIcon } from './Icons';
import { isMobileDeviceV3 } from '../../utils/StyleUtils';
import { MinWidth } from '../../constants/Dims';
import { useSearch } from '../../context/SearchContext';

const SearchBar: React.FC = () => {
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const { query, setQuery, setResults } = useSearch();

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchQuery = event.target.value;
        setQuery(searchQuery);
        if (searchQuery) {
            // Implement your search logic here and set the results
            const searchResults: any[] = []; // Replace with actual search logic
            setResults(searchResults);
        } else {
            setResults([]);
        }
    };

    const handleFocus = () => {
        setIsFocused(true);
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };

    const handleBlur = () => {
        if (inputRef.current && !inputRef.current.value) {
            setIsFocused(false);
            setQuery('');
        }
    };

    const isMob = isMobileDeviceV3(MinWidth);

    return (
        <div className="search-container" onClick={handleFocus}>
            <ul>
                {!isFocused && (
                    <li>
                        <div style={isMob ? { fontSize: '150%' } : { fontSize: '80%' }}>
                            {!isMob && <SearchIcon />} поиск
                        </div>
                    </li>
                )}
                <li>
                    <input
                        ref={inputRef}
                        type="text"
                        className="search-input"
                        placeholder="Поиск"
                        onFocus={() => setIsFocused(true)}
                        onBlur={handleBlur}
                        value={query}
                        onChange={handleSearch}
                    />
                </li>
            </ul>
        </div>
    );
};

export default SearchBar;
