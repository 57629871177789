import React from "react";

type ImgDisplayProps = {
    src: string,
    style?: React.CSSProperties,
}
const ImgDisplay: React.FC<ImgDisplayProps> = ({src,
    style = {width: "100px", height: "100px"}
}) => {
    return (
        <img src={src} style={style} alt={"img"}/>
    )
}

export default ImgDisplay