export function checkRolesExist(requiredRole: string): boolean {
    const rolesString = localStorage.getItem("Roles");

    if (!rolesString) {
        return false;
    }

    const rolesArray: string[] = rolesString.split(";")
    if (rolesArray.includes("*")){
        return true
    }
    return rolesArray.includes(requiredRole)
}