import React from "react";
import {Link, useLocation} from "react-router-dom";
import "./MenuAdmin.css"
import {handleRelogin} from "../login/Login";
import {checkRolesExist} from "./Utils";
import {Roles} from "../../../constants/Roles";

export const MenuAdmin: React.FC = () => {
    const location = useLocation()
    const author = checkRolesExist(Roles.Author.Add)
    const editor = checkRolesExist(Roles.Main.Add)
    const sp = checkRolesExist(Roles.SP.Edit)
    return (
        <div className={"menu-admin"}>
            <div>
                <Link style={location.pathname === "/crm/publication" ?  {textDecoration: "underline"} :{}} className={"menu-admin-tab"} to={"/crm/publication"}>Публикации</Link>
                {author && <Link style={location.pathname === "/crm/author" ? {textDecoration: "underline"}:{}} className={"menu-admin-tab"} to={"/crm/author"}>Авторы</Link>}
                {editor && <Link style={location.pathname === "/crm/editor" ? {textDecoration: "underline"}:{}} className={"menu-admin-tab"} to={"/crm/editor"}>Редакторы</Link>}
                {sp && <Link style={location.pathname === "/crm/static_page" ? {textDecoration: "underline"}:{}} className={"menu-admin-tab"} to={"/crm/static_page"}>Статические страницы</Link>}
                <Link to={""} onClick={handleRelogin}>Выход</Link>
            </div>
        </div>
    )
}