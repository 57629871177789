export function checkRolesExist(requiredRoles: string[]): boolean {
    const rolesString = localStorage.getItem("Roles");

    if (!rolesString) {
        return false;
    }

    const rolesArray: string[] = rolesString.split(",")

    return requiredRoles.every(role => rolesArray.includes(role));
}