import React, { useState} from 'react';
import {Field, Formik, Form, FieldArray} from 'formik';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { AuthorData } from "../../../constants/Types";
import { convertUTCDateToLocalDate } from "../../homepage/HomepageUtils";
import {convertToDesiredFormat} from "../../../utils/DatetimeUtils";
import {filterEditor} from "../../../common/FilterUtils";

type FieldConfig = {
    name: string;
    type: string;
    label: string;
    validation?: any;
    isRichText?: boolean;
};

type EditAddCoreProps = {
    data: any;
    validationSchema: any;
    onSubmit: (values: any) => Promise<void>;
    users?: AuthorData[];
};

export const EditAddCore: React.FC<EditAddCoreProps> = ({ data, validationSchema, onSubmit, users }) => {
    const fields = generateFieldConfig(data);
    const [submitting, setSubmitting] = useState(false);

    // @ts-ignore
    return (
        <>
            <Formik
                validationSchema={validationSchema}
                initialValues={data}
                onSubmit={async (values: any) => {
                    try {
                        setSubmitting(true);
                        if (values["date_to_publish"]){
                            values["date_to_publish"] = convertToDesiredFormat(values["date_to_publish"])
                        }
                        await onSubmit(values);
                        setSubmitting(false);
                    } catch (e) {
                        console.error(e);
                        setSubmitting(false);
                    }
                }}
            >
                {({ values, setFieldValue, errors, touched  }) => (
                    <Form>
                        <h1>Редактирование</h1>
                        {fields.map((field, index) => (
                            <div key={index}>
                                <label htmlFor={field.name}>{field.label}:</label>
                                {field.type === 'quill' ? (
                                    <ReactQuill
                                        theme="snow"
                                        // @ts-ignore
                                        value={values[field.name]}
                                        onChange={(newValue) => setFieldValue(field.name, newValue)}
                                    />
                                ) : field.type === 'select-array' ? (
                                    users && <UserSelector fieldName={field.name} users={users} />
                                ) : (
                                    <>
                                    <Field name={field.name} type={field.type} />
                                    </>
                                )}
                                <>{errors[field.name] && touched[field.name] && (
                                    // @ts-ignore
                                    <div className="error" style={{color: "red"}}>{errors[field.name]}</div>
                                )}</>
                            </div>
                        ))}
                        {!submitting && <button type="submit">Submit</button>}
                    </Form>
                )}
            </Formik>
        </>
    );
};

type UserSelectorProps = {
    fieldName: string;
    users: AuthorData[];
};

const UserSelector: React.FC<UserSelectorProps> = ({ fieldName, users }) => {
    if (fieldName.includes("editor")){
        users = filterEditor(users)
    }
    return (
        <FieldArray
            name={fieldName}
            render={arrayHelpers => (
                <div>
                    {arrayHelpers.form.values[fieldName]?.map((userId: string, index: number) => (
                        <div key={index}>
                            <Field as="select" name={`${fieldName}.${index}`}>
                                {users.map(user => (
                                    <option key={user._id} value={user._id}>
                                        {user.name} {user.surname}
                                    </option>
                                ))}
                            </Field>
                            <button type="button" onClick={() => arrayHelpers.remove(index)}>Remove</button>
                        </div>
                    ))}
                    <button type="button" onClick={() => arrayHelpers.push('')}>Add User</button>
                </div>
            )}
        />
    );
};


function generateFieldConfig(data: object, parentKey = ''): FieldConfig[] {
    return Object.keys(data).flatMap((key) => {
        const fieldKey = parentKey ? `${parentKey}.${key}` : key;
        const fieldType = typeof (data as any)[key];
        let type: string;
        let isRichText = false;

        if (key.includes('html')){
            type = 'quill'
            isRichText = true
        } else if (key.includes('date')){
            type = 'datetime-local';
            // @ts-ignore
            data[key] = convertUTCDateToLocalDate(data[key]);
        } else if (key.includes('_ids')){
            type = 'select-array'
        } else if (Array.isArray((data as any)[key])) {
            type = 'text';
        } else {
            switch (fieldType) {
                case 'string':
                    type = 'text';
                    break;
                case 'boolean':
                    type = 'checkbox';
                    break;
                case 'object':
                    if ((data as any)[key] !== null) {
                        return generateFieldConfig((data as any)[key], fieldKey);
                    } else {
                        type = 'text';
                    }
                    break;
                default:
                    type = 'text';
            }
        }
        return [{
            name: fieldKey,
            type: type,
            label: fieldKey.replace(/\./g, ' ').replace('_', ' ').toUpperCase(),
            isRichText: isRichText,
        }];
    });
}
