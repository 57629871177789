import {Link} from "react-router-dom";
import React from "react";
import "./ProfilePublications.css"
import {PublicationData} from "../constants/Types";

type ProfilePublicationsProps = {
    pubAsAuthor: PublicationData[],
    titleText: string
}

const ProfilePublications: React.FC<ProfilePublicationsProps> = ({pubAsAuthor, titleText}) => {
    if (pubAsAuthor.length === 0){
        return (
            <div></div>
        )
    }
    return (
        <div className={"container-pub"}>
        <div className={"title-container"}>
            <div className={"title"}>{titleText}</div>
            <div className={"title-line"}></div>
        </div>

    {pubAsAuthor && pubAsAuthor.map(pub => (
            <Link to={`/publication/${pub._id}`}>
            <h4>{pub.title}</h4>
                </Link>
            ))}
        </div>
    )

}

export default ProfilePublications