import React, { createContext, useContext, useState, ReactNode } from 'react';

interface SearchContextProps {
    query: string;
    setQuery: (query: string) => void;
    results: any[];
    setResults: (results: any[]) => void;
}

const SearchContext = createContext<SearchContextProps | undefined>(undefined);

export const SearchProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [query, setQuery] = useState<string>('');
    const [results, setResults] = useState<any[]>([]);

    return (
        <SearchContext.Provider value={{ query, setQuery, results, setResults }}>
            {children}
        </SearchContext.Provider>
    );
};

export const useSearch = (): SearchContextProps => {
    const context = useContext(SearchContext);
    if (!context) {
        throw new Error('useSearch must be used within a SearchProvider');
    }
    return context;
};
