import React from "react";
import {editAuthors} from "../../../request/Request";
import {MenuAdmin} from "../common/MenuAdmin";
import {EditAddCore} from "../common/EditAddCore";
import {authorInstance, authorValidationSchema, editorInstance, editorValidationSchema} from "./Constants";
import ImageUploader from "../common/ImgDownloader";
import {
    AuthorDelete,
    AuthorManipulation, EditorDelete,
    EditorManipulation, PublicationDelete,
    PublicationManipulation,
    StaticPageManipulation
} from "../../../constants/Roles";
import {checkRolesExist} from "../common/Utils";
import CustomErrorModal from "../../../common/CustomErrorModal";

type AddUsersCoreProps = {
    isEditor: boolean;
};

const AddUsersCore: React.FC<AddUsersCoreProps> = ({isEditor}) => {
    const ok = checkRolesExist([isEditor ? EditorManipulation : AuthorManipulation])
    if (!ok) {
        return <CustomErrorModal msg={"у вас сюда нет доступа"} />
    }
    return (
        <>
            <MenuAdmin />
            <ImageUploader/>
            {isEditor &&
                <div>
                    роли:
                    {AuthorManipulation},{EditorManipulation},{StaticPageManipulation},{PublicationManipulation},{EditorDelete},{AuthorDelete},{PublicationDelete}
                </div>
            }
            <EditAddCore
                data={isEditor? editorInstance: authorInstance}
                validationSchema={isEditor ? editorValidationSchema : authorValidationSchema}
                onSubmit={editAuthors}
            />
        </>
    );
};

export default AddUsersCore;
